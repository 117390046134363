import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoaderService } from './loader.service';
import { AdalService } from 'adal-angular4';
import { ActivatedRoute, Router } from '@angular/router';

interface IclLabsApiRequest {
	withCredentials?: boolean;
	headers?: HttpHeaders;
	params?: any;
	body?: any;
	responseType?: any
}

interface IclLabsApiResponse {
	ok: boolean;
	data?: any;
	error?: string;
};

@Injectable({
	providedIn: 'root'
})
export class IclLabsAPIService {

	logOutTimer;
	ONE_HOUR = 1000 * 60 * 60;
	// currentRouterName use to check if its the first time routing to this page for showing the loader.
	currentRouterName = '';
	temp_base64: string;
	file_name: any;

	constructor(
		private http: HttpClient,
		private loaderService: LoaderService,
		private adalService: AdalService,
		private router: Router,
		private route: ActivatedRoute,

	) { }

	async get(url: string, options: IclLabsApiRequest = {}): Promise<any> {
		// console.log('icl-labs-service -> ', url, options);
		this.showLoader();
		options.withCredentials = false;
		options.headers = this.prepareOptions();
		return await this.parseRequest(this.http.get(`${environment.iclLabsUrl}${url}`, options).toPromise())
	}
	async getData(url: string, options: IclLabsApiRequest = {}): Promise<any> {
		this.showLoader();
		options.withCredentials = false;
		options.headers = this.prepareOptions();
		options.responseType = 'blob'
		return await this.parseRequest(this.http.get(`${environment.iclLabsUrl}${url}`, options).toPromise())
	}
	
	async put(url: string, options: IclLabsApiRequest = {}): Promise<any> {
		this.showLoader();
		options.withCredentials = false;
		options.headers = this.prepareOptions();
		return await this.parseRequest(this.http.put(`${environment.iclLabsUrl}${url}`, options.body, options).toPromise())
	}
	
	async post(url: string, options: IclLabsApiRequest = {}): Promise<any> {
		this.showLoader();
		options.withCredentials = false;
		options.headers = this.prepareOptions();
		return await this.parseRequest(this.http.post(`${environment.iclLabsUrl}${url}`, options.body, options).toPromise());
	}
	
	async delete(url: string, options: IclLabsApiRequest): Promise<any> {
		this.showLoader();
		options.withCredentials = false;
		options.headers = this.prepareOptions();
		return await this.parseRequest(this.http.delete(`${environment.iclLabsUrl}${url}`, options).toPromise());
	}
	encodeFileToBase64(file: File) {
		return new Promise<string>((resolve, reject) => {
		  const reader = new FileReader();
		  reader.onload = () => {
			const base64String = reader.result as string;
			resolve(base64String.split(',')[1]); // Extracting base64 string from data URL
		  };
		  reader.onerror = error => reject(error);
		  reader.readAsDataURL(file);
		});
	  }
	
	
	async postData(url: string, data: { key: string, files: any[] }[], options: IclLabsApiRequest = {}): Promise<any> {
		this.showLoader();
		options.withCredentials = false;
		const formData = new FormData();
		// options.headers = this.prepareOptions();
		options.headers = new HttpHeaders()
		 options.headers = options.headers.append('Content-Type', 'application/json');
		options.headers = options.headers.append('Authorization', `Bearer ${this.adalService.userInfo.token}`);
	
		await Promise.all(data.map(async _data => {
			await Promise.all(_data.files.map(async file => {
			  try {
				const base64String = await this.encodeFileToBase64(file);
				const dotIndex = file.name ? file.name.lastIndexOf('.') : -1;
     			 const fileExtension = dotIndex !== -1 ? file.name.substring(dotIndex + 1) : '';	
				 console.log(fileExtension)
				 console.log(file.name)
				 this.file_name=file.name
				formData.append(_data.key, base64String);
				this.temp_base64=base64String
			  } catch (error) {
				console.error('Error encoding file to base64:', error);
			  }
			}));
		
		  }));
				
				if (options.body) {
					for (let key in options.body)
					formData.append(key, options.body[key]);
			delete options.body;
				}
				try{
					return await this.parseRequest(this.http.post(`${environment.iclLabsUrl}${url}`, {formData:this.temp_base64,file_name:this.file_name}, options).toPromise());
				}
				catch{
					alert('somthing get worng')
					window.location.reload()
				}
			

	
		
	}
	
	private async parseRequest(req: Promise<any>): Promise<any> {
		try {
			const response: IclLabsApiResponse = await req;
			await this.setLogoutTimer();
			this.hideLoader();
			if (response)
			return response;
			throw new Error(response.error);
		} catch (err) {
			this.hideLoader();
			console.error(err);
			throw new Error('global.error');
		}
	}

	private showLoader(): void {
		const frag = this.route.snapshot.fragment;
		if (this.currentRouterName !== this.router.url) {

			this.loaderService.setStatus(true);
			setTimeout(() => {
				this.currentRouterName = this.router.url;
			}, 1000);

		}
	}
	
	private hideLoader(): void {
		this.loaderService.setStatus(false);
	}

	private prepareOptions(): HttpHeaders {
		let headers = new HttpHeaders();
		headers = headers
			.set('Content-Type', 'application/json')
			.set('Authorization', `Bearer ${this.adalService.userInfo.token}`);
		return headers;
	}

	private async setLogoutTimer() {
		const isAutomaticLogout = localStorage.getItem('logoutTimeout');
		clearTimeout(this.logOutTimer);
		if (isAutomaticLogout === 'true') {
			console.log(isAutomaticLogout)
			this.logOutTimer = setTimeout(async () => {
				await this.logout();
			}, this.ONE_HOUR)
		}
	}

	private async logout() {
		try {
			localStorage.removeItem('logoutTimeout');
			this.adalService.logOut();
		} catch (err) {
			console.error('[SessionService]', '(logout)', err);
		}
	}
}

