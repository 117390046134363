import { Injectable } from "@angular/core";
import { Aspect } from '../base/Aspect';
import { IclLabsAPIService } from './icl-labs.api.service';

@Injectable({
    providedIn: "root"
})

export class AspectService {
    constructor(private iclLabsApiService: IclLabsAPIService) { }

    async getAspects(params?:any): Promise<Aspect[]> {
        try {
            let aspects: Aspect[] = [];
            const result = await this.iclLabsApiService.get('/aspect', { params })
            if (result && result.aspects) {
                result.aspects.map(aspect => aspects.push(new Aspect(aspect)));
            }
            return aspects;
        } catch (err) {
            console.error(err);
        }
    }

    async createAspect(aspect: Partial<Aspect>): Promise<Aspect> {
        try {
            const result = await this.iclLabsApiService.post('/aspect', { body: aspect });
            if (result && result.aspect) {
                return new Aspect(result.aspect)
            } else {
                throw new Error('something went wrong')
            }
        }
        catch (err) {
            console.error(err);
        }
    }

    async editAspect(id: number, aspect: Partial<Aspect>): Promise<boolean> {
        try {
            const result = await this.iclLabsApiService.put('/aspect/' + id, { body: aspect });
            if (result && result.success) {
                return result.success;
            }
            return false;
        } catch (err) {
            console.error(err);
        }
    }

    async deleteAspect(id: number, params?: any): Promise<boolean> {
        try {
            const result = await this.iclLabsApiService.delete('/aspect/' + id, { params });
            if (result && result.success) {
                return result.success;
            }
            return false;
        } catch (err) {
            console.error(err);
        }
    }
}