import { Injectable } from "@angular/core";
import { Sample } from "../base/Sample";
import { STATUS_CONST } from "../base/Status";
import { User } from "../base/User";

@Injectable({
    providedIn: "root"
})
export class PermissionsService {


    //ROUTING PERMISSION
    canViewSampleEdit(user: User, sample: Sample): boolean {
        let ans: boolean = false;
        if (user.Permission.admin||user.Permission.labTechnician||user.Permission.labManager) {
            ans = true;
        } else if (user.Permission.customer && (this.isUserCreatedGivenSample(user, sample))) {
            ans = true;
        }
        return ans;
    }

    canViewSampleCreate(user: User): boolean {
        return user.Permission.admin || user.Permission.customer;
    }

    canViewLabArea(user: User): boolean {
        return user.Permission.labManager || user.Permission.labTechnician;
    }

    canViewAdminArea(user: User): boolean {
        return user.Permission.admin;
    }
    canViewWorkerLabArea(user: User): boolean {
        return true;
    }

    canViewSampleEditAsLab(user: User, sample: Sample): boolean {
        let ans: boolean = false;
        //ONLY IF THIS SAMPLE
        if (sample.status !== STATUS_CONST.STATUS_CREATED) {
            if (user.Permission.labManager || user.Permission.labTechnician) {
                ans = true;
            }
        }
        return ans;
    }

    //EDITING PERMISSIONS
    canEdit(user: User, sample: Sample, input?: string): boolean {
        let ans = false;
        if (!input && this.isUserCreatedGivenSample(user, sample)) {
            ans = true;
        } else if ((user.Permission.admin && input && LIST_ADMIN_CAN_EDIT.includes(input))||(user.Permission.labTechnician&&sample.userId==user.id)) {
            ans = true;
        }
        else if (this.canViewLabArea(user) && input && LIST_LAB_CAN_EDIT.includes(input)) {
            ans = true;
        }
        // else if ( LIST_LAB_CAN_EDIT.includes(input)) {
        //     ans = true;
        // }
        return ans;
    }

    isLabManager(user: User): boolean {
        return user.Permission.labManager;
    }

    isAdmin(user: User): boolean {
        return user.Permission.admin;
    }

    isLabTec(user: User): boolean {
        return user.Permission.labTechnician;
    }
    isCustomer(user: User): boolean {
        return user.Permission.customer;
    }

    isGuest(user: User): boolean {
        let ans: boolean = false;
        if (!this.isAdmin(user) && !this.canViewLabArea(user) && !this.isCustomer(user)) {
            ans = true;
        }
        return ans;
    }

    public isUserInGuestList(user: User, sample: Sample): boolean {
        return sample.emailsString.indexOf(user.email) !== -1;
    }

    //HELPERS
    private isUserCreatedGivenSample(user: User, sample: Sample): boolean {
        return user.id === sample.userId;
    }

   
}

export const LIST_ADMIN_CAN_EDIT = [
    'emails'
]

export const LIST_LAB_CAN_EDIT = [
    'status',
    'comments',
    'results',
    'dangersSection',

]

