import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class HeaderService {
    constructor() { }

    private _headerParams = {
        routeName: ''
    }
    private headerParams = new BehaviorSubject<HeaderParams>(this._headerParams);
    public headerParams$ = this.headerParams.asObservable();

    setHeaderParams(headerParams: HeaderParams) {
        this._headerParams = headerParams;
        this.headerParams.next(this._headerParams)
    }
    setHeaderRouteName(routeName: string) {
        this._headerParams.routeName = routeName;
        this.headerParams.next(this._headerParams);
    }
}

export interface HeaderParams {
    routeName: string
}