import { Entity } from './Entity';
import { Lab } from './Lab';

export const permissionList = {
    'admin': 'אדמיניסטרטור',
    'customer': 'לקוח',
    'labTechnician': 'עובד מעבדה',
    'labManager': 'מנהל מעבדה'
}

export class User extends Entity {
    id: number;
    number: number;
    first_name: string;
    last_name: string;
    personal_number: number;
    email: string;
    phoneNumber: string;
    active: boolean;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    factoryId: string;
    Permission: Permission;
    labsRolePermission: Lab[];
    Factory: Factory;


    constructor(obj: Partial<User>) {
        super(obj);
        if (this.Permission) {
            this.Permission.admin = this.Permission.admin ? this.Permission.admin : false;
            this.Permission.labManager = this.Permission.labManager ? this.Permission.labManager : false;
            this.Permission.labTechnician = this.Permission.labTechnician ? this.Permission.labTechnician : false;
            this.Permission.customer = this.Permission.customer ? this.Permission.customer : false;
            this.Permission.guest = this.Permission.guest ? this.Permission.guest : false;
        }

    }

    get permissions(): string {
        let permissions: string = '';
        for (let key in this.Permission) {
            if (key != 'id' && key != 'userId' && this.Permission[key]) {
                if (permissions.length > 0 && permissionList[key]) {
                    permissions += ',' + permissionList[key]
                } else if (permissionList[key]) {
                    permissions += permissionList[key]
                }
            }
        }
        return permissions
    }

    get hasLabPermissions(): boolean {
        return this.Permission && (this.Permission.labManager || this.Permission.labTechnician);
    }

    get labPermission(): 'manager' | 'tech' {
        if (this.Permission.labManager)
            return 'manager'
        else return 'tech'
    }
}

export interface SampleStatusLog {
    createdAt: string;
    deletedAt: string;
    id: number;
    sampleId: number;
    status: number;
    updatedAt: string;
    userId: number
}

export interface Permission {
    id?: number;
    admin?: boolean;
    labManager?: boolean;
    labTechnician?: boolean;
    customer?: boolean;
    guest?: boolean;
    userId?: number;
    labId? : number;
    defaultLab?: number;
    factoryId?: string;
}


export class Factory {
    id?: number;
    name: string;
    number: string;
    companyId: number;
    labId : number;
    constructor(args?: Partial<Factory>) {
        this.id = args.id;
        this.name = args.name;
        this.number = args.number;
        this.companyId = args.companyId;
        this.labId = args.labId;
    }
}