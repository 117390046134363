import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, Output, EventEmitter, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialsModule } from 'src/app/materials.module';
import { SessionService } from '../../services/session.service';
import { Sample } from '../../base/Sample';
import { IclLabsService } from 'src/app/modules/icl-labs/icl-labs.service';

@Component({
    templateUrl: './matter.component.html',
    styleUrls: ['./matter.component.scss'],
    selector: 'global-matter'
})


export class MatterComponent  implements OnInit  {
   
    @Input() matters: MatterInput[];
    @Output() mattersChange = new EventEmitter()

    @Input() disabled : boolean = false;
    public gas=[];
    public liquid=[];
    public solid=[];
    input: any;
    controlName='new';

    gDynamicForm!: FormGroup;
    lDynamicForm!: FormGroup;
    sDynamicForm!: FormGroup;
    flag = false;
    gDynamicArr=[];
    lDynamicArr=[];
    sDynamicArr=[];
    index: any;
    currentLabId: number;
    public sample: Sample;
    solid_lengthOfexample=0
    liquid_lengthOfexample=0
    gas_lengthOfexample=0
    solid_lenth: number;
    gas_lenth: number;
    liquid_lenth: number;
    constructor(private formBuilder: FormBuilder, private sessionService: SessionService, private iclLabsService: IclLabsService,) {
        this.currentLabId=sessionService.getLabId();
        
     
     
        console.log( this.currentLabId)
        this.solid=[]
        this.gas=[]
        this.liquid=[]
        this.gDynamicForm = this.formBuilder.group({
          
        });
        this.lDynamicForm = this.formBuilder.group({
          
        });
        this.sDynamicForm = this.formBuilder.group({
          
        });
        this.sessionService.set_solid(this.solid);
        this.sessionService.set_gas(this.gas);
        this.sessionService.set_liquid(this.liquid);
        this.gDynamicForm.reset();
        this.lDynamicForm.reset();
        this.sDynamicForm.reset();
       
       
       
   
      }
          async ngOnInit() {
            const id=localStorage.getItem('current_sample_id')
            this.sample = await this.iclLabsService.getSample(parseInt(id));
            console.log(this.sample)
         
         
            this.currentLabId=this.sessionService.getLabId();
        this.gDynamicForm = this.formBuilder.group({
          
        });
        this.lDynamicForm = this.formBuilder.group({
          
        });
        this.sDynamicForm = this.formBuilder.group({
          
        });
        this.updateExampels()
     
   
         
    
       
    }
   
    
    updateExampels(){
        const gas=Object.keys(this.sample.gas_exampels)
        this.gas_lengthOfexample = gas.length;
        for (let i = 0; i < this.gas_lengthOfexample; i++) {
            this.controlName='newG'+i
            this.gDynamicForm.addControl(this.controlName, new FormControl(this.sample.gas_exampels[i]));
            console.log(this.sample.gas_exampels[i]);
            this.gas[i]=this.sample.gas_exampels[i]
            
          }
     
          const liquid=Object.keys(this.sample.liquid_exampels)
          this.liquid_lengthOfexample = liquid.length;
          for (let i = 0; i < this.liquid_lengthOfexample; i++) {
              this.controlName='newL'+i
              this.lDynamicForm.addControl(this.controlName, new FormControl(this.sample.liquid_exampels[i]));
              this.liquid[i]=this.sample.liquid_exampels[i]
              
            }
            const solid=Object.keys(this.sample.solid_exampels)
            this.solid_lengthOfexample = solid.length;
            for (let i = 0; i < this.solid_lengthOfexample; i++) {
                this.controlName='newS'+i
                this.sDynamicForm.addControl(this.controlName, new FormControl(this.sample.solid_exampels[i]));
                this.solid[i]=this.sample.solid_exampels[i]
              }
              this.flag=true
          
      
    }
    
   

    onMatterChange(index) {
        
        if(index!=undefined)
        this.index=index;
        if(index !== undefined && !this.matters[index].activate)
            this.matters[index].amount = 0;
        this.mattersChange.emit(this.matters);
        if(this.currentLabId==1){
        if(this.matters[this.index].type==0 && this.matters[this.index].activate==true){
        this.gDynamicArr.length=this.matters[matterType.gas].amount;
        this.gas.length=this.matters[matterType.gas].amount;
        if(this.sample!==undefined){
            for (let i = 0; i < this.matters[matterType.gas].amount; i++) {
                this.gas[i]=this.sample.gas_exampels[i]
                
              }
              this.sessionService.set_gas(this.gas);
        }
    
    
        }
        if(this.matters[this.index].type==1 && this.matters[this.index].activate==true){
        this.lDynamicArr.length=this.matters[matterType.liquid].amount;
        this.liquid.length=this.matters[matterType.liquid].amount;
        if(this.sample!==undefined){
            for (let i = 0; i < this.matters[matterType.liquid].amount; i++) {
                this.liquid[i]=this.sample.liquid_exampels[i]
                
              }
              this.sessionService.set_liquid(this.liquid);
        }
     
        }
        if(this.matters[this.index].type==2 && this.matters[this.index].activate==true){
        this.sDynamicArr.length=this.matters[matterType.solid].amount;
        this.solid.length=this.matters[matterType.solid].amount;
        if(this.sample!==undefined){
            for (let i = 0; i < this.matters[matterType.solid].amount; i++) {
                this.solid[i]=this.sample.solid_exampels[i]
                
              }
              this.sessionService.set_solid(this.solid);
        }
       
        }
    }
    if(this.currentLabId==3){
        if(this.matters[this.index].type==0 && this.matters[this.index].activate==true){
        this.gDynamicArr.length=this.matters[matterType.gas].amount;
        this.gas.length=this.matters[matterType.gas].amount;
        if(this.sample!==undefined){
            for (let i = 0; i < this.matters[matterType.gas].amount; i++) {
                this.gas[i]=this.sample.gas_exampels[i]
                
              }
              this.sessionService.set_gas(this.gas);
        }
      
    
        }
        if(this.matters[this.index].type==1 && this.matters[this.index].activate==true){
        this.lDynamicArr.length=this.matters[matterType.liquid].amount;
        this.liquid.length=this.matters[matterType.liquid].amount;
        if(this.sample!==undefined){
            for (let i = 0; i < this.matters[matterType.liquid].amount; i++) {
                this.liquid[i]=this.sample.liquid_exampels[i]
                
              }
              this.sessionService.set_liquid(this.liquid);
        }
    
        }
        if(this.matters[this.index].type==2 && this.matters[this.index].activate==true){
        this.sDynamicArr.length=this.matters[matterType.solid].amount;
        this.solid.length=this.matters[matterType.solid].amount;
        if(this.sample!==undefined){
            for (let i = 0; i < this.matters[matterType.solid].amount; i++) {
                this.solid[i]=this.sample.solid_exampels[i]
                
              }
              this.sessionService.set_solid(this.solid);
        }
       
        }
    }
    }
    setFormLength(amount){
        if(this.index==0){
            if( this.gas.length<= this.gas_lengthOfexample){

            }
            else{
                if(this.gas_lengthOfexample==0){
                    this.gas_lenth=-1
                }
                else{
                  this.gas_lenth=this.gas_lengthOfexample
                }
                for (let i =   this.gas_lenth; i < amount; i++) {
                    this.controlName='newG'+i
                    this.gDynamicForm.addControl(this.controlName, new FormControl(''));
                  }
            }
         
              this.flag=true;
        }
        if(this.index==1){
            if( this.liquid.length<= this.liquid_lengthOfexample){

            }
            else{
                if(this.liquid_lengthOfexample==0){
                    this.liquid_lenth=-1
                }
                else{
                  this.liquid_lenth=this.liquid_lengthOfexample
                }
                for (let i =  this.liquid_lenth; i < amount; i++) {
                    this.controlName='newL'+i
                    this.lDynamicForm.addControl(this.controlName, new FormControl(''));
                  }
            }
          
              this.flag=true;
        }
        if(this.index==2){
            if( this.solid.length<= this.solid_lengthOfexample){

            }
            else{
                if(this.solid_lengthOfexample==0){
                    this.solid_lenth=-1
                }
                else{
                  this.solid_lenth=this.solid_lengthOfexample
                }
                for (let i =  this.solid_lenth; i < amount; i++) {
                    this.controlName='newS'+i
                    this.sDynamicForm.addControl(this.controlName, new FormControl(''));
                  }
            }
         
              this.flag=true;
        }
      

    }
    onChangeTextInG(y){
        const GdynamicName='newG'+y;
        this.gas[y]=this.gDynamicForm.get(GdynamicName).value;
            console.log( this.gas[y]);
            this.sessionService.set_gas(this.gas);
      
    }
    onChangeTextInL(y){
        const LdynamicName='newL'+y;
        this.liquid[y]=this.lDynamicForm.get(LdynamicName).value;
        console.log( this.liquid[y]);
        this.sessionService.set_liquid(this.liquid);
      
    }
    onChangeTextInS(y){
        const SdynamicName='newS'+y;
        this.solid[y]=this.sDynamicForm.get(SdynamicName).value;
        console.log( this.solid[y]);
        this.sessionService.set_solid(this.solid);
      
    }


    getNameOfType(matter:MatterInput): string {
        let name: string = ''
        switch (matter.type) {
            case 0:
                name = 'תרחיף'
                break;
            case 1:
                name = 'נוזל'
                break;
            case 2:
                name = 'מוצק'
                break;
        }
        return name;
    }

}

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MaterialsModule,
        FormsModule,
        ReactiveFormsModule,
        
    ],
    exports: [
        MatterComponent
    ],
    declarations: [
        MatterComponent
    ]
})

export class MatterModule { }

export interface MatterInput {
    type: number;
    amount: number;
    activate: boolean;
}

export enum matterType {
    gas = 0,
    liquid = 1,
    solid = 2
}