import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { IclRoute } from 'src/app/globals/base/IclRoute';
import { Sample } from 'src/app/globals/base/Sample';
import { AddBtn, DeleteBtn, FilterInput, SaveBtn, SaveName, SearchBtn } from 'src/app/globals/components/ruler/ruler.component';
import { IclLabsService } from '../../icl-labs.service';
import { Status, STATUS_CONST, statuses } from 'src/app/globals/base/Status';
import { AccordionData } from 'src/app/globals/components/accordion/accordion.component';
import { User } from "src/app/globals/base/User";
import { interval, Subscriber } from "rxjs";
import { SessionService } from "src/app/globals/services/session.service";
import { PermissionsService } from "src/app/globals/services/permissions.service";
import { MatSelectChange } from "@angular/material/select";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { Option } from "src/app/globals/components/ruler/ruler.component";
import { savedSearchesService } from 'src/app/globals/services/savedSearches.service';


import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { SamplesFilter, SampleSortData } from "src/app/globals/services/samplesFilter.service";
import { Sort } from "@angular/material/sort";
import { SampleService } from "src/app/globals/services/sample.service";
import { sample } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { BudgetItem } from "src/app/globals/base/BudgetItem";
import { UserService } from "src/app/globals/services/user.service";
import { SampleEditComponent } from "../sample-edit/sample-edit.component";
import { MatDialog } from "@angular/material/dialog";
import { downloadWindow } from '../../components/download-window/download-window.component';


// 
const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
    }
}

export interface Attr {
    label: string;
    value: any;
}

@Component({
    selector: 'samples-table',
    styleUrls: ['./samples-table.component.scss'],
    templateUrl: 'samples-table.component.html',
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})

 export class SamplesTableComponent implements OnInit, OnDestroy, IclRoute {  
    pageIndex: number = 0;

    _sortBy: 'project' | 'status_change_time' = 'project';
    _sortWay: 'ASC' | "DESC" | 'NULL' = 'ASC';
    _resetSortByArray: Sample[];
    tableSort: Sort;
    accordionSort: SampleSortData = null;


    routeName: string = "הזמנות"
    samples: Sample[] = [];
    filteredSamples: Sample[] = [];
    MINUTE = 60 * 1000;
    

    user: User;
    updateSubscriber = new Subscriber;
    timerSubscriber = new Subscriber;

    //MODAL 
    isFilterOpen: boolean = false;

    //RULER DATA AND MODAL
    filterInputs: FilterInput[] = [];
    extraFilterInputs: FilterInput[] = [];
    addBtn: AddBtn;
    saveBtn: SaveBtn;
    deleteBtn: DeleteBtn;
    searchBtn: SearchBtn;
    attrs: Attr[] = []
    attrs2: Attr[] = []
    isExtraFiltersOpen: boolean = false;
    labels: Attr[]=[];
    saveSearch: [];
    result;
    selectedSave: string = '';
    selected_attr;
    filterColums: FilterInput[];
    into_save_key=false;
    
   
    //TABLE DATA
    displayedColumns: { key: string, value: string }[] = [];
    rowAction;
    rowDownload
    dataSource;
    hadValue=false;
    notEmpty: boolean = true;
    download_image_path='<img src="assets/images/download.png" alt="" width="100">';
    //ACCORDION DATA
    accordionData: AccordionData[] = [];
    private subscriber = new Subscriber();
    save_name: string;
    filters_col: { key: string; value: string; }[];
    filters: any;
    temp_arr= [];
    saveSearchFilters: any;
    data_table: any;
    res_status: number;
    filter_res: any;
    fromDate: Date;
    pastDate: number;
    budgets:BudgetItem[]
    current_clicked_Colum: any;
    lab_id: number;
    order_show_labs: any[];
    current_user: any;
    Factories: import("src/app/globals/base/User").Factory[];
    factory_user_list: any;
    canSeeDownload=false
    rowStatusUpdate
    constructor(
        private router: Router,
        private sessionService: SessionService,
        private permissionsService: PermissionsService,
        private iclLabsService: IclLabsService,
        private samplesFilter: SamplesFilter,
        public sampleService: SampleService,
        private savedSearchesService: savedSearchesService,
        private userService: UserService,
        private SampleEdit:SampleEditComponent,
        private dialog: MatDialog
    ) {    
        this.lab_id=this.sessionService.getLabId() 
        this.subscriber.add(
            this.sessionService.user.subscribe((user: User) => {
                if (user){
                    this.ngOnInit();
                }
            })
        )
       
      
      
    }
    openModal(files): void {
        const dialogRef = this.dialog.open(downloadWindow, {
          width: '300px',
          data: { files: files},
        });
    
        dialogRef.afterClosed().subscribe(result => {
          console.log('Modal closed with result:', result);
        });
      }
    

    async ngOnInit() {
        this.fromDate = new Date();
        this.pastDate = this.fromDate.getDate() - 30;
        this.fromDate.setDate(this.pastDate);
        this.update()
        this.timerSubscriber.add(interval(20* this.MINUTE).subscribe(async () => {
            if (this.user && this.permissionsService.canViewLabArea(this.user)) {
                //THIS IS GOING TO SHUT DOWN LOADER GLOBALLY
                localStorage.setItem('globalLoaderShutdown', 'true');
                await this.update();
                //THIS IS GOING TO MAKE LOADER WORK AS USUAL
                localStorage.removeItem('globalLoaderShutdown');
            }
        }));
        var result=await this.savedSearchesService.getsavedSearche();
        this.saveSearch=result['Savedsearches'];
        
        // this.savedSearchesService.savesearch$.subscribe(res=>{
        //     this.saveSearch = res;
        //    })
        // this.createSaveSearche();
    }
   

    async update() {
        this.updateSubscriber.unsubscribe();
        //WE NEED TO USE THIS PROMISE BECAUSE WE USE SETTIMEOUT LATER IN THE CODE
        return new Promise((res, rej) => {
            this.updateSubscriber.add(
                this.sessionService.user.subscribe(async (user: User) => {
                    this.user = user;
                    if (this.user) {
                        this.attrs = this.samplesFilter.attrs;
                        if (this.attrs.length > 0)
                            this.isExtraFiltersOpen = true;
                        else
                            this.isExtraFiltersOpen = false;

                        this.isFilterOpen = false;
                        this.setPageIndex();
                        await this.setSearchInputs();
                        if(!(localStorage.getItem('default')==='yes'))
                         await this.putAttrsInSearchInputs(this.attrs);
                      //  await this.setSamples();
                   
                      await this.searchSample();
                        this.setRulerBtns();



                        //LOCAL STORAGE TABLE SORTING
                        if (this.samplesFilter && this.samplesFilter.sort && this.samplesFilter.sort.tabel)
                            this.tableSort = this.samplesFilter.sort.tabel;
                            if(this.into_save_key===false)
                                 this.setTable();


                        //LOCAL STORAGE ACCORDION SORTING
                        if (this.samplesFilter && this.samplesFilter.sort && this.samplesFilter.sort.accordion) {
                            this.accordionSort = this.samplesFilter.sort;
                            this._sortBy = this.accordionSort.accordion.sortBy;
                            this._sortWay = this.accordionSort.accordion.sortWay;
                            this.sortBy(this._sortBy);
                        } else {
                            this.setAccordion()
                        }

                        setTimeout(async () => {
                            await this.searchSample();
                            if (this.accordionSort?.accordion) {
                                this._sortBy = this.accordionSort.accordion.sortBy;
                                this._sortWay = this.accordionSort.accordion.sortWay;
                                this.sortBy(this._sortBy);
                            }
                            res('done')
                        }, 50);
                    }
                })
            )
        })

    }

    handleTableSortChange(sort: Sort) {
        this.tableSort = sort;
        this.samplesFilter.setSort({ tabel: this.tableSort });
    }


   setTable() {
        this.getSamplesAndPutInTable();
        this.rowAction = this.goToSample.bind(this);
        this.rowDownload = this.goToDownload.bind(this);
        this.rowStatusUpdate = this.goToStatusUpdate.bind(this);
        this.lab_id=this.sessionService.getLabId() 
        if(this.into_save_key===false){
            if (this.lab_id==1||this.lab_id==3)
            //brom
            {
                this.displayedColumns = [
                    { key: "project", value: "שם פרויקט" },
                    { key: "BudgetItem", value: "סעיף תקציבי" },
                    { key: "client_name", value: "שם הלקוח" },
                    { key: "factory", value: "מפעל" },
                    { key: "id", value: "מזהה הזמנה" },
                    // { key: "handed_to_lab", value: "תאריך נמסר למעבדה" },
                    { key: "status", value: "סטטוס נוכחי" },
                    { key: "status_change_time", value: "מועד שינוי הסטטוס" },
                    { key: "changed_by", value: "מבצע שינוי סטטוס נוכחי" },
                    { key: "priority", value: "קטגוריה" },
                    { key: "amount", value: "מס' דוגמאות" },
                    // { key: "number", value: "מס' פרוייקט" },
                    // { key: "work_hours", value: "שעות עבודה" },
                    { key: "download", value: "הורדת תוצאות" },
                    { key: "preference", value: "עדיפות" },
                ];
            }
            //lab 2- sdom
            else {
                this.displayedColumns = [
                    { key: "id", value: "מזהה הזמנה" },
                    { key: "project", value: "שם פרויקט" },
                    { key: "creation_date", value: "תאריך יצירת הזמנה" },
                     { key: "BudgetItem", value: "סעיף תקציבי" },
                     { key: "amount", value: "מס' דוגמאות" },
                    { key: "client_name", value: "שם הלקוח" },
                    { key: "factory", value: "מפעל" },
                    { key: "priority", value: "קטגוריה" },
                    { key: "status", value: "סטטוס נוכחי" },
                    { key: "status_change_time", value: "מועד שינוי הסטטוס" },
                    { key: "changed_by", value: "מבצע שינוי סטטוס נוכחי" },
                    { key: "download", value: "הורדת תוצאות" },
                    { key: "preference", value: "עדיפות" },
                ];
            }
       
        
        }
  
        //  this.into_save_key=false;
    }
    get_clicked(value){
        this.current_clicked_Colum=value
    }
//    async filter_col_func(keys){
//         this.into_save_key=true;
//         this.filters=keys;
//         this.filterColums = await this.getDataForfilterscol();
//         this.filterColums[0].attr.value=keys;  
//          this.displayedColumns=[];
//         //  this.displayedColumns=keys;
//         keys = keys.filter(key => key !=  this.current_clicked_Colum);
//         keys.forEach( key=>{
            
//             var value= this.filters_col.find(temp=> temp.key===key).value? this.filters_col.find(temp=> temp.key===key).value:'';
//             if( !this.displayedColumns.find(x=>x.key===key)){
//                 this.displayedColumns.push( { key: key, value: value })
//                 console.log(this.displayedColumns);
//                 this.set_displayedColumns(this.displayedColumns);
                
//             }
       
//         })
//         var value= this.filters_col.find(temp=> temp.key===this.current_clicked_Colum).value? this.filters_col.find(temp=> temp.key===this.current_clicked_Colum).value:'';
//         this.displayedColumns.push( { key: this.current_clicked_Colum, value: value })
//         this.set_displayedColumns(this.displayedColumns);
        
      
     
//     this.getSamplesAndPutInTable();
//         this.rowAction = this.goToSample.bind(this);
//         this.rowDownload = this.goToDownload.bind(this);
        
//     }
    set_displayedColumns(displayedColumns){
        this.filter_res=displayedColumns;

    }
    get_displayedColumns(){
        return this.filter_res

    }
    rest_field(){
        this.displayedColumns=[];
        this.filterColums.forEach(input=>{
            input.attr.value=[];
        })
        
    }

    private getSamplesAndPutInTable() {
   
    if((this.iclLabsService.isLabManager(this.user)||this.iclLabsService.isLabTec(this.user))){
        this.canSeeDownload=true
    }

      
        this.dataSource = null;
        setTimeout(() => {
            if (this.filteredSamples) {
                this.filteredSamples = this.filteredSamples.sort(function (a, b) {
                    return b.id - a.id
                })
                this.dataSource = new MatTableDataSource(
                   
                    this.filteredSamples.map((sample: Sample, index: number) => {
                        return {
                            position: index,
                            project: sample.project,
                            client_name: sample.creator_name,
                            factory: this.getFactoryBlock(sample),
                            id: sample.id,
                            status: this.getStatusBlock(sample.status),
                            status_change_time: { value: new Date(sample.status_change_time).getTime(), label: sample.getAsDateTime(sample.status_change_time) },
                            // this.getTimeBlock(sample),
                            changed_by: sample.changed_by,
                            priority: sample.priority,
                            BudgetItem:  sample.BudgetItem?sample.BudgetItem.name+' '+'-'+' '+sample.BudgetItem.number:''                         ,
                            amount: sample.amount,
                            number: sample.number,
                            work_hours: sample.work_hours,
                            download: sample.excel&&((sample.status==7&&this.iclLabsService.isCustomer(this.user))||(this.canSeeDownload))?this.download_image_path:'None',
                            sample_excel: sample.excel,
                            preference: sample.preference,
                            creation_date:{ value: new Date(sample.status_created).getTime(), label: sample.getAsDateTime(sample.status_created) },
                            // handed_to_lab:{ value: new Date(sample.handed_to_lab_status).getTime(), label: sample.getAsDateTime(sample.handed_to_lab_status) },
                            res_status:sample.status


                            
                        }
                        
                 
                        
                    }));
                    this.set_sample_table(this.dataSource);
            }
            
        }, 50);
    }

    private async setSamples() {
        this.samples = await this.iclLabsService.getSamples();
        if (this.samples) {
            this.filteredSamples = this.samples;
            this.notEmpty = true;
        } else {
            this.notEmpty = false;
        }
    }

    accordionSortBy(by) {
        console.log(by);
    }

    private setAccordion(_filteredSamples?: Sample[]) {
        this.accordionData = null;
        if (this.filteredSamples) {
            if (_filteredSamples) {
                this.filteredSamples = _filteredSamples;
            } else {
                this.filteredSamples = this.filteredSamples.sort(function (a, b) {
                    return b.id - a.id
                })
            }
            this.accordionData = this.filteredSamples.map((sample: Sample, index: number) => {
                return {
                    header: {
                        entity: sample,
                        project: sample.project,
                        time: this.getTimeBlock(sample),
                        status: sample.status,
                    },
                    content: [
                        { key: 'שם הלקוח', value: sample.creator_name },
                        { key: 'מזהה הזמנה', value: sample.id },
                        { key: 'סעיף תקציבי', value: sample.BudgetItem?sample.BudgetItem.name+"-"+sample.BudgetItem.number : ''  },
                        { key: 'מועד שינוי הסטטוס', value: this.getTimeBlock(sample) },
                        { key: 'מבצע שינוי הסטטוס', value: sample.changed_by },
                        { key: 'תיעדוף', value: sample.Category ? sample.Category.name : '' },
                        { key: 'כמות הזמנות', value: sample.amount },
                        { key: '', value: this.getEditBlock(sample), fn: this.goToSample.bind(this) },
                        { key: 'מס פרוייקט', value: sample.number },
                        // { key: 'הורדת תוצאות', value:'',fn:this.sampleService.getExcel(sample.excel) },
                        
                    ]
                   
                }
            });
        }
    }

    sortBy(by) {
        if (by !== this._sortBy && this._resetSortByArray) {
            this.filteredSamples = this._resetSortByArray;
            this._sortBy = by;
        }
        this.samplesFilter.setSort({
            accordion: {
                sortBy: this._sortBy,
                sortWay: this._sortWay
            }
        })
        if (this.filteredSamples)
            switch (by) {
                case "project":
                    if (this._sortWay === 'ASC') {
                        this._resetSortByArray = this.filteredSamples;
                        this._sortWay = 'DESC';
                        this.filteredSamples.sort((a, b) => a.project > b.project ? -1 : 1);
                        this.setAccordion(this.filteredSamples);
                    } else if (this._sortWay === 'DESC') {
                        this._sortWay = 'NULL';
                        this.filteredSamples.sort((a, b) => a.project > b.project ? 1 : -1);
                        this.setAccordion(this.filteredSamples);
                    } else {
                        this._sortWay = 'ASC';
                        this.setAccordion()
                    }
                    return;
                case "status_change_time":
                    if (this._sortWay === 'ASC') {
                        this._resetSortByArray = this.filteredSamples;
                        this._sortWay = 'DESC';
                        this.filteredSamples.sort((a, b) => a.status_change_time > b.status_change_time ? -1 : 1);
                        this.setAccordion(this.filteredSamples);
                    } else if (this._sortWay === 'DESC') {
                        this._sortWay = 'NULL';
                        this.filteredSamples.sort((a, b) => a.status_change_time > b.status_change_time ? 1 : -1);
                        this.setAccordion(this.filteredSamples);
                    } else {
                        this._sortWay = 'ASC';
                        this.setAccordion()
                    }
                    return;
            }


    }

    ngOnDestroy() {
        this.updateSubscriber.unsubscribe();
        this.timerSubscriber.unsubscribe();

    }

    clearPageIndex() {
        localStorage.removeItem('pageIndex');
    }

    setPageIndex() {
        this.pageIndex = localStorage.getItem('pageIndex') ? parseInt(localStorage.getItem('pageIndex')) : 0;
    }

    toggleFilterModal() {
        this.isFilterOpen = true;
    }

    async setSearchInputs() {
        this.extraFilterInputs = null;
        this.filterInputs = null
        this.filterColums=null;
        this.extraFilterInputs = await this.getDataForExtraInputs();
        this.extraFilterInputs = this.extraFilterInputs.map(input => {
            input.fn = this.pushToAttr.bind(this);
            input.attr.value = "";
            return input;
        })
        // this.filterColums = await this.getDataForfilterscol();
        // this.filterColums = this.filterColums.map(input => {
        //     input.fn = this.pushToAttr.bind(this);
        //     input.attr.value = "";
        //     return input;
        // })
        this.filterInputs = FILTER_INPUTS.map(input => {
            input.fn = this.pushToAttr.bind(this);
            if(input.type != 'date'){
            input.attr.value = "";
            } else{
                this.pushToAttr(input)
            }
            return input;
        })
    }


    async putAttrsInSearchInputs(attrs: Attr[]) {
        if (attrs.length > 0) {
            if (this.extraFilterInputs.length > 0 && this.filterInputs.length > 0) {
                attrs.map((attr: Attr) => {
                    this.extraFilterInputs = this.extraFilterInputs.map(filter => {
                        if (filter.attr.label === attr.label) {
                            filter.attr.value = attr.value;
                        }
                        return filter;
                    });

                    this.filterInputs = this.filterInputs.map(filter => {
                        if (filter.attr.label === attr.label) {
                            filter.attr.value = attr.value;
                        }
                        return filter;
                    });

                })
            }
        }
        
    }


    setRulerBtns() {
        if (this.user) {
            if (this.canViewSampleCreate()) {
                this.addBtn = {
                    title: 'צור הזמנה',
                    fn: this.addSample.bind(this)
                }
            }

            this.searchBtn = {
                title: 'חיפוש',
                fn: this.searchSample.bind(this)
            };
            // this.saveBtn = {
            //     title: 'שמור וריאנט',
            //     fn: this.saveSample.bind(this)
            // };
            // this.deleteBtn = {
            //     title: 'מחק וריאנט נבחר',
            //     fn: this.deleteSaveSample.bind(this)
            // };
        }
    }

    canViewSampleCreate() {
        return this.permissionsService.canViewSampleCreate(this.user);
    }

    addSample() {
        this.router.navigateByUrl('/sample-create')
    }

    goToSample(event: Event, sample) {
       
        this.router.navigateByUrl('sample/' + sample.id);
    }
    async goToDownload(event: Event, sample) {
       if(this.lab_id==2)
         this.sampleService.getExcel(sample.sample_excel); //for sdom
        else{ //for brom 
            let extrnal_sample = await this.iclLabsService.getSample(sample.id); 
            console.log(extrnal_sample)   
            this.openModal(extrnal_sample.excel) 
        }
     
    }
    doYesAction() {
        // Code to execute when the user confirms
        console.log("User clicked Yes!");
      }
      
       doNoAction() {
        // Code to execute when the user cancels
        console.log("User clicked No!");
      }
      
    goToStatusUpdate(event: Event, sample) {

        if (this.user.Permission.admin || this.user.Permission.labManager) {
            const confirmed = window.confirm('האם להפוך הזמנה לסטטוס "הסתיים ואושר"?');
            if (confirmed) {
              // User clicked Yes
              this.SampleEdit.updateSample(sample);
            } else {
              // User clicked No
              this.doNoAction();
            }
          }
   
       
      
    }

 
    
//    getExcel(sample) {
//     this.sampleService.getExcel(sample.excel);
       
//     }

    getTableData(data) {
        return new MatTableDataSource(data);
    }

    getEditBlock(sample: Sample) {
        return `
        <div class="mat-focus-indicator icl-btn dark font-weight-bold size-small mat-raised-button mat-button-base"  >
            <span>
            ערוך הזמנה
            </span>
        </div>`
    }

    getStatusBlock(status) {
        if (statuses[status])
            return `
            <div class="status-table">
                <div class="color-line bg-${status}" style="{ background : ${statuses[status].color}; }"></div>
                <div class="status-name mx-3">${statuses[status].title}</div>
            </div>
        `
    }

    getTimeBlock(sample: Sample) {
        return this.dataBetween(sample.getAsDate(sample.status_change_time), sample.getAsTime(sample.status_change_time));
    }

    getClientBlock(sample: Sample): string {
        // if (sample.Factory && sample.Users && sample.Users[0])
        //     return this.dataBetween(sample.Users[0].name, sample.Factory.name);
        /*if (sample.Factory && sample.statusLog && sample.statusLog[0] && sample.statusLog[0].User)
            return this.dataBetween(sample.statusLog[0].User.name, sample.Factory.name);
        else if (sample.Factory) {
            return sample.Factory ? sample.Factory.name : '';
        } else if (sample.statusLog) {
            return sample.statusLog && sample.statusLog[0] && sample.statusLog[0].User ?  sample.statusLog[0].User.name : '';
        }*/
        return sample.statusLog && sample.statusLog[0] && sample.statusLog[0].User ? sample.statusLog[0].User.first_name + ' ' + sample.statusLog[0].User.last_name : '';

    }
    getFactoryBlock(sample: Sample): string {
        // if (sample.Factory && sample.Users && sample.Users[0])
        //     return this.dataBetween(sample.Users[0].name, sample.Factory.name);
        return sample.Factory ? sample.Factory.name : '';
    }

    private dataBetween(first: any, second: any): string {
        return `<div class="d-flex justify-content-between">
                    <div class="mx-1">${first}</div>
                    <div class="mx-1">${second}</div>
                </div>`
    }

    private async getDataForExtraInputs(): Promise<FilterInput[]> {
        let inputs = EXTRA_FILTER_INPUTS;
        if(this.lab_id==2){
            inputs=inputs.filter(x=>x.label!='תיאור דוגמה')
        }
        const factories = await this.iclLabsService.getFactories();
        factories.sort((a,b) => a.name > b.name ? 1 : -1).sort((a,b) => a.name > b.name ? 1 : -1)
        const budgets = await this.iclLabsService.getBudgets();
        budgets.sort((a,b) => a.number > b.number ? 1 : -1).sort((a,b) => a.number > b.number ? 1 : -1)
        this.current_user=await this.userService.getCurrentUser(this.lab_id);
        //  this.Factories= factories;
        //   this.factory_user_list = this.current_user.Permission.factoryId ? this.current_user.Permission.factoryId.split(',') : [];
        //   this.factory_user_list[0]=this.factory_user_list[0].slice(1)
        //   this.factory_user_list[this.factory_user_list.length-1]=this.factory_user_list[this.factory_user_list.length-1].slice(0,-1)
       
        this.factory_user_list = factories.map(factory=>factory.id);
        if((this.lab_id==3)||(this.lab_id==1)){
            this.budgets = budgets.filter(budget => (budget['Factory'].labId==1||budget['Factory'].labId==3))
        }
        else{
            this.budgets = budgets.filter(budget => this.factory_user_list.includes(budget.factoryId));  
        }
         this.budgets.sort((a,b) => a.number > b.number ? 1 : -1).sort((a,b) => a.number > b.number ? 1 : -1)
        const categories = await this.iclLabsService.getCategories();
        categories.sort((a,b) => a.name > b.name ? 1 : -1).sort((a,b) => a.name > b.name ? 1 : -1)
        const users = await this.iclLabsService.getUsers();
        users.sort((a,b) => a.first_name > b.first_name ? 1 : -1).sort((a,b) => a.first_name > b.first_name ? 1 : -1)

        //factories       
        inputs[1].options = factories.map(factory => {
            return {
                label: factory.name,
                value: factory.id
            }           
        })
  
        //users
        inputs[2].options = users.map(user => {
            return {
                label: user.first_name + ' ' + user.last_name,
                value: user.first_name + ' ' + user.last_name
            }
        })
        //UNIQLIST
        inputs[2].options = this.uniqBy(inputs[2].options, JSON.stringify)

        //budgets
        inputs[3].options = this.budgets.map(budget=> {
            return {
                label: budget.number + " - " + budget.name,
                value: budget.id
            }
        })

        //categories
        inputs[4].options = categories.map(category => {
            return {
                label: category.name,
                value: category.id
            }
        })
        return inputs;
    }
    // private async getDataForfilterscol(): Promise<FilterInput[]> {
    //     let inputs = filter_col;
    //      this.filters_col = [
    //         { key: "project", value: "שם פרויקט" },
    //         { key: "client_name", value: "שם הלקוח" },
    //         { key: "factory", value: "מפעל" },
    //         { key: "id", value: "מזהה הזמנה" },
    //         { key: "status", value: "סטטוס נוכחי" },
    //         { key: "status_change_time", value: "מועד שינוי הסטטוס" },
    //         { key: "changed_by", value: "מבצע שינוי סטטוס נוכחי" },
    //         { key: "priority", value: "קטגוריה" },
    //         { key: "amount", value: "מס' דוגמאות" },
    //         { key: "number", value: "מס' פרוייקט" },
    //         { key: "work_hours", value: "שעות עבודה" },
    //         { key: "download", value: "הורדת תוצאות" },
    //         { key: "preference", value: "עדיפות" },
    //     ];
    //     //factories       
    //     inputs[0].options =  this.filters_col.map(filter => {
    //         return {
    //             label: filter.value,
    //             value: filter.key
    //         }           
    //     })
  
    //  console.log(inputs)
    //     return inputs;
    // }


    async searchSample(): Promise<void> {   
      
        if (this.attrs.length === 0)
            return    
      
        const results = await this.iclLabsService.searchSample(this.buildQuery(this.attrs));
        
        if (results && results.length > 0) {
            this.notEmpty = true;
            this.filteredSamples = results;
            console.log( this.filteredSamples)
            this.setAccordion();
              this.setTable();
            this.isFilterOpen = false;
            
        } else {
            this.notEmpty = false;
        }
        
        
       
    
}
set_sample_table(res){
    this.data_table= res;

}
get_sample_table(){
    return this.data_table;

}
    // async saveSample(): Promise<void> {   
    //     var saves=await this.savedSearchesService.getsavedSearche();
    //     console.log(saves)
    //     this.save_name=localStorage.getItem('save_name') 
    //     if (this.attrs.length === 0){
    //         console.log("cannot save empty forms")
    //         return 
    //     }
    //     const save_exist=saves.filter(x=>x.save_name===this.save_name)
    //     if(save_exist.length!=0){
    //          this.savedSearchesService.updatesavedSearche({
    //             id:save_exist[0].id,
    //             filters: this.filters,
    //             userId: this.user.id,
    //             labId: this.user.Permission.labId,
    //             categoryId:this.attrs.find(({label,value})=> label=== "categoryId")? this.attrs.find(({label,value})=> label=== "categoryId").value:null ,
    //             status:this.attrs.find(({label,value})=> label=== "status")? this.attrs.find(({label,value})=> label=== "status").value:null ,
    //             project: this.attrs.find(({label,value})=> label=== "project")? this.attrs.find(({label,value})=> label=== "project").value:null,
    //             budgetItemId: this.attrs.find(({label,value})=> label=== "budgetItemId")? this.attrs.find(({label,value})=> label=== "budgetItemId").value:null,
    //             userName:  this.attrs.find(({label,value})=> label=== "userName")? this.attrs.find(({label,value})=> label=== "userName").value:null,
    //             factoryId: this.attrs.find(({label,value})=> label=== "factoryId")? this.attrs.find(({label,value})=> label=== "factoryId").value:null,
    //             numOfOrders: this.attrs.find(({label,value})=> label=== "numOfOrders")? this.attrs.find(({label,value})=> label=== "numOfOrders").value:null,
    //             samlingPoint: this.attrs.find(({label,value})=> label=== "samlingPoint")? this.attrs.find(({label,value})=> label=== "samlingPoint").value:null,
    //             work_hours: this.attrs.find(({label,value})=> label=== "work_hours")? this.attrs.find(({label,value})=> label=== "work_hours").value:null, 
    //             save_name: this.save_name,
    //             example_description: this.attrs.find(({label,value})=> label=== "example_description")? this.attrs.find(({label,value})=> label=== "example_description").value:null,
    //         })
            
            
         
            
    //     }
   
    
    //     else
    //     {
    //         try {
              
    //             await this.savedSearchesService.createsavedSearche({
                   
    //                filters: this.filters,
    //                userId: this.user.id,
    //                labId: this.user.Permission.labId,
    //                categoryId:this.attrs.find(({label,value})=> label=== "categoryId")? this.attrs.find(({label,value})=> label=== "categoryId").value:null ,
    //                status:this.attrs.find(({label,value})=> label=== "status")? this.attrs.find(({label,value})=> label=== "status").value:null ,
    //                project: this.attrs.find(({label,value})=> label=== "project")? this.attrs.find(({label,value})=> label=== "project").value:null,
    //                budgetItemId: this.attrs.find(({label,value})=> label=== "budgetItemId")? this.attrs.find(({label,value})=> label=== "budgetItemId").value:null,
    //                userName:  this.attrs.find(({label,value})=> label=== "userName")? this.attrs.find(({label,value})=> label=== "userName").value:null,
    //                factoryId: this.attrs.find(({label,value})=> label=== "factoryId")? this.attrs.find(({label,value})=> label=== "factoryId").value:null,
    //                numOfOrders: this.attrs.find(({label,value})=> label=== "numOfOrders")? this.attrs.find(({label,value})=> label=== "numOfOrders").value:null,
    //                samlingPoint: this.attrs.find(({label,value})=> label=== "samlingPoint")? this.attrs.find(({label,value})=> label=== "samlingPoint").value:null,
    //                work_hours: this.attrs.find(({label,value})=> label=== "work_hours")? this.attrs.find(({label,value})=> label=== "work_hours").value:null, 
    //                save_name: this.save_name,
    //                example_description: this.attrs.find(({label,value})=> label=== "example_description")? this.attrs.find(({label,value})=> label=== "example_description").value:null,
    //            })
    //            // await this.update();
    //        } catch (err) {
            
    //        }
          
         
    //     }
       
    
    //         window.location.reload();
            
    // }
    // async deleteSaveSample(value): Promise<void> {    
       
    //         try {
           
    //              await this.savedSearchesService.deletesavedSearche(value)
    //             // await this.update();
    //         } catch (err) {
             
    //         }
    // }
    //  handleSaves(value,savesearch) {
    //     savesearch.forEach((value1) => {
    //         if (value1['save_name']==value){
    //             console.log('yes');

    //             this.selected_attr = {   
    //                 categoryId:value1['categoryId'],
    //                 project: value1['project'],
    //                 budgetItemId: value1['budgetItemId'],
    //                 userName:  value1['userName'],
    //                 status:  value1['status'],
    //                 factoryId: value1['factoryId'],
    //                 numOfOrders: value1['numOfOrders'],
    //                 samlingPoint: value1['samlingPoint'],
    //                 work_hours: value1['work_hours'], 
    //                 example_description: value1['example_description'], 
    //               };
                  
    //               this.saveSearchFilters=value1['filters']
    //         }
          
            
            
    //       });
       
    //      this.pushSaveAttr(this.selected_attr);
        
      
    //       if(this.saveSearchFilters!=null)
    //      this.filter_col_func( this.saveSearchFilters);    
    // }


    uniqBy(a, key) {
        var seen = {};
        return a.filter(function (item) {
            var k = key(item);
            return seen.hasOwnProperty(k) ? false : (seen[k] = true);
        })
    }

    buildQuery(attrs: Attr[]): string {       
        let result = '?';
        if (attrs) {
            attrs.map((attr: Attr) => {
                if (result === '?')
                    result += `${attr.label}=${attr.value}`
                else
                    result += `&${attr.label}=${attr.value}`
            });
        }
        return result;
    }

    pushToAttr(input: FilterInput) {
        if (!this.isAttrExist(input.attr)) {
            if (input.attr.value)
                this.attrs.push({
                    label: input.attr.label,
                    value: input.attr.value
                })
        }
        else {
            this.attrs = this.attrs.filter((attr: Attr) => {
                if ((input.attr.value)) {
                    if (attr.label === input.attr.label) {
                        attr.value = input.attr.value
                    }
                } else {
                    if (attr.label === input.attr.label)
                        return false
                }
                return true;
            })
        }
        this.samplesFilter.setAttrs(this.attrs);
    }
     async pushSaveAttr(attr) {
        this.attrs=[];
        console.log(attr);
        const res =  Object.keys(attr);
        console.log(res);
        for (let i = 0; i < res.length; i++){
         if(res[i]==="userId"&& attr["userId"]!= null){
          
                     this.attrs.push({
               label: "userId",
               value: attr["userId"]
           })
        
         }
             if(res[i]==="labId" && attr["labId"]!= null){
               this.attrs.push({
         label: "labId",
         value: attr["labId"]
         })
         }
         if(res[i]==="categoryId"&& attr["categoryId"]!=null){
           this.attrs.push({
         label: "categoryId",
         value: attr["categoryId"]
         })
         }
         if(res[i]==="project"&& attr["project"]!=null){
           this.attrs.push({
         label: "project",
         value: attr["project"]
         })
         }
         if(res[i]==="budgetItemId"&& attr["budgetItemId"]!=null){
           this.attrs.push({
         label: "budgetItemId",
         value: attr["budgetItemId"]
         })
         }
         if(res[i]==="userName"&& attr["userName"]!=null){
           this.attrs.push({
         label: "userName",
         value: attr["userName"]
         })
         }
         if(res[i]==="factoryId"&& attr["factoryId"]!=null){
           this.attrs.push({
         label: "factoryId",
         value: attr["factoryId"]
         })
         }
         if(res[i]==="numOfOrders"&& attr["numOfOrders"]!=null){
           this.attrs.push({
         label: "numOfOrders",
         value: attr["numOfOrders"]
         })
         }
         if(res[i]==="samlingPoint"&& attr["samlingPoint"]!=null){
           this.attrs.push({
         label: "samlingPoint",
         value: attr["samlingPoint"]
         })
         }
         if(res[i]==="work_hours"&& attr["work_hours"]!=null){
           this.attrs.push({
         label: "work_hours",
         value: attr["work_hours"]
         })
         }
         if(res[i]==="save_name"&& attr["save_name"]!=null){
            this.attrs.push({
          label: "save_name",
          value: attr["save_name"]
          })
          }
          if(res[i]==="example_description"&& attr["example_description"]!=null){
            this.attrs.push({
          label: "example_description",
          value: attr["example_description"]
          })
          }
          if(res[i]==="status"&& attr["status"]!=null){
            this.attrs.push({
          label: "status",
          value: attr["status"]
          })
          }
     
     
        }
    
        this.samplesFilter.setAttrs(this.attrs);
           this.filterInputs = FILTER_INPUTS.map(input => {
            input.fn = this.pushToAttr.bind(this);
            if(input.type != 'date'){
            input.attr.value = "";
            } else{
                this.pushToAttr(input)
            }
            return input;
        })
         this.putAttrsInSearchInputs(this.attrs);
        
              await this.searchSample();
      
     
     }


    isAttrExist(attr: Attr): boolean {
        let ans: boolean = false;
        if (this.attrs.find(_attr => _attr.label === attr.label))
            ans = true;
        return ans;
    }
  

    resetAll() {
        this.filterInputs = FILTER_INPUTS.map(input => {
            input.fn = this.pushToAttr.bind(this);
            if(input.attr.label == 'fromCreatedAt'){
            input.attr.value = this.fromDate.toISOString();
            } else{
                this.pushToAttr(input)
            }
            if(input.attr.label == 'toCreatedAt'){
                input.attr.value = new Date().toISOString();
                } else{
                    this.pushToAttr(input)
                }
            return input;
        })
       
        this.samplesFilter.setAttrs([])
      
        this.clearPageIndex();
        this.update();
       
    }
   

    //FOR MODAL SEARCH
    handleChange(e, input: FilterInput) {
        if (e instanceof MatDatepickerInputEvent) {
            if (e.target.value) {
                input.attr.value = new Date(e.target.value).toISOString();
                input.fn(input)
            }
        }
        else if (e instanceof MatSelectChange) {
            input.attr.value = Number.isInteger(e.value) ? JSON.stringify(e.value) : e.value
            input.fn(input)
        }
        else if (e.target && input.fn) {
            input.attr.value = Number.isInteger(e.target.value) ? JSON.stringify(e.target.value) : e.target.value
            input.fn(input)
        }
    }

    initialValue(value: any, options: Option[]) {
        if (value) {
            this.hadValue=true;
            if (this.areOptionsIntegers(options)) {
                if (!Number.isInteger(value)) {
                    return JSON.parse(value)
                } else {
                    return value;
                }
            } else {
                return value;
            }
        }
        return undefined;
    }
 
    areOptionsIntegers(options: Option[]): boolean {
        try {
            return Number.isInteger(options[0].value);
        } catch (err) {
            console.error(err)
        }
    }
}
const fromDate = new Date();
const pastDate = fromDate.getDate() - 30;
fromDate.setDate(pastDate);


const FILTER_INPUTS: FilterInput[] = [
    {
        type: 'number',
        label: 'מזהה הזמנה',
        attr: { label: 'id', value: '' },
        fn: () => {
            console.log('מזהה הזמנה')
        }
    },
    {
        type: 'date',
        label: 'מתאריך',
        attr: { label: 'fromCreatedAt', value: fromDate.toISOString() },
        fn: () => {
            console.log('מתאריך')
        }
    },
    {
        type: 'date',
        label: 'עד תאריך',
        attr: { label: 'toCreatedAt', value: new Date().toISOString() },
        fn: () => {
            console.log('עד תאריך')
        }
    },
    {
        type: 'multi-select',
        label: 'סטאטוס הזמנה',
        attr: { label: 'status', value: '' },
        fn: () => {
            console.log('סטאטוס הזמנה')
        },
        options: statuses.map((status: Status) => {
            return {
                label: status.title,
                value: status.id
            }
        })

    },

]

// const filter_col: FilterInput[] = [
//     {
//       type: 'multi-select',
//       label: "בחירת עמודות",
//       attr: { label: '', value: '' },
//       fn: () => {
//          console.log("בחירת/הסרת עמודות")
//       },
//       options: []
//   }
// ];
   

const EXTRA_FILTER_INPUTS: FilterInput[] = [
    {
        type: 'number',
        label: "מס' דוגמאות",
        attr: { label: 'numOfOrders', value: '' },
        fn: () => {
            console.log('כמות הזמנות')
        }
    },
    {
        type: 'multi-select',
        label: 'שם מפעל',
        attr: { label: 'factoryId', value: '' },
        fn: () => {
            console.log('שם מפעל')
        },
        options: []
    }, {
        type: 'multi-select',
        label: 'שם הלקוח',
        attr: { label: 'userName', value: '' },
        fn: () => {
            console.log('שם הלקוח')
        },
        options: []
    },
    {
        type: 'multi-select',
        label: 'סעיף תקציבי',
        attr: { label: 'budgetItemId', value: '' },
        fn: () => {
            console.log('סעיף תקציבי')
        },
        options: []
    },
    {
        type: 'multi-select',
        label: 'קטגוריה',
        attr: { label: 'categoryId', value: '' },
        fn: () => {
            console.log('קטגוריה')
        },
        options: []
    },
    {
        type: 'text',
        label: 'שם פרויקט',
        attr: { label: 'project', value: '' },
        fn: () => {
            console.log('שם פרויקט')
        }
    },
    {
        type: 'text',
        label: "נק דיגום/שלב בתהליך",
        attr: { label: 'samlingPoint', value: '' },
        fn: () => {
            console.log('שם דגימה')
        }
    },
    // {
    //     type: 'text',
    //     label: 'משך ש"ע לחיוב',
    //     attr: { label: 'work_hours', value: '' },
    //     fn: () => {
    //         console.log('משך ש"ע לחיוב')
    //     }
    // },
    {
        type: 'text',
        label: 'תיאור דוגמה',
        attr: { label: 'example_description', value: '' },
        fn: () => {
            console.log('משך ש"ע לחיוב')
        }
    },
   
    
    
]
