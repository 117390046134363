import { Injectable } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { Attr } from "src/app/modules/icl-labs/routes/samples-table/samples-table.component";


export interface SampleSortData {
    accordion?: {
        sortWay: 'ASC' | 'DESC' | 'NULL',
        sortBy: 'project' | 'status_change_time'
    },
    tabel?: Sort
}


@Injectable({
    providedIn: "root"
})

export class SamplesFilter {

    get sort(): SampleSortData {
        return this.getSortFromLocalStorage();
    }

    setSort(sort: SampleSortData): void {
        this.setSortToLocalStorage(sort)
    }

    get attrs(): Attr[] {
        return this.getAttrsFromLocalStorage();
    }

    setAttrs(attrs: Attr[]): void {
        this.setAttrsToLocalStorage(attrs)
    }

    private setAttrsToLocalStorage(attrs: Attr[]): void {
        localStorage.setItem('attrs', JSON.stringify(attrs));
    }

    private getAttrsFromLocalStorage(): Attr[] {
        let attrs: Attr[] = [];
        const ls = localStorage.getItem('attrs');
        if (ls) {
            attrs = JSON.parse(ls);
        }
        return attrs;
    }

    private setSortToLocalStorage(sort: SampleSortData): void {
        localStorage.setItem('sort', JSON.stringify(sort));
    }

    private getSortFromLocalStorage(): SampleSortData {
        let sort: any;
        const ls = localStorage.getItem('sort');
        if (ls) {
            sort = JSON.parse(ls);
        }
        return sort;
    }



}