<div @fadeInOut *ngIf="isOpen" class="loader-wrapper d-flex justify-content-center align-items-center">
    <!-- <div class="lds-grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div> -->
    <img [src]="'/assets/images/loader.gif'">
</div>