import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { User } from '../base/User';
import { VLADI } from '../mockup/profile';
import { AdalService } from 'adal-angular4';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { PermissionsService } from './permissions.service';
import { Lab } from '../base/Lab';
import { SamplesTableComponent } from 'src/app/modules/icl-labs/routes/samples-table/samples-table.component';
import { SamplesFilter } from './samplesFilter.service';
export interface Attr {
  label: string;
  value: any;
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public user = new BehaviorSubject<User>(undefined);
  public labSession: Lab;
  attrs: Attr[] = []
  private selectedSave= new Subject<any>();
  public selectedSave$= this.selectedSave.asObservable();
  public gas=[];
  public liquid=[];
  public solid=[];
  userId: User;
  constructor(
    private adalService: AdalService,
    private userService: UserService,
    private permissionsService: PermissionsService,
   
    
    // private labSession : any
  ) {
    this.adalService.init(environment.adalConfig);
  }

  async update(labId = null, options: { noLoader?: boolean } = {}): Promise<User> {
    try {
      const currentLabId = labId ? labId : this.getLabId();
      const iclLabUser: User = await this.userService.getCurrentUser(currentLabId);
      this.userId=iclLabUser;
      this.setLabSession(currentLabId, iclLabUser.labsRolePermission);
      iclLabUser.Permission = this.labSession.permission;
      this.setLogoutTimeOut(iclLabUser);
      this.user.next(iclLabUser);
      this.selectedSave.next('');

      return iclLabUser;
    } catch (err) {
      console.error('[SessionService]', err);
    }
  }

  async logout() {
    try {
      localStorage.removeItem('logoutTimeout');
      this.adalService.logOut();
    } catch (err) {
      console.error('[SessionService]', '(logout)', err);
    }
  }

  private setLogoutTimeOut(user: User) {
    if (user)
      if (!this.permissionsService.canViewLabArea(user) && !this.permissionsService.canViewLabArea(user)) {
        //USER IS NOT ADMIN NOR LAB
        localStorage.setItem('logoutTimeout', 'true');
      } else {
        //USER MAYBE ADMIN OR LAB
        localStorage.setItem('logoutTimeout', 'false');
      }
  }
  setLabSession(labId = null, labsRolePermission) {
    
    if (this.user) {
      this.labSession = labId ? labsRolePermission.filter((lab: Lab) => lab.id == labId)[0] : labsRolePermission.filter((lab: Lab) => lab.permission.defaultLab == 1)[0];
    }else{
      this.labSession = null;
    }
  }
  getLabId() {
    if(!this.labSession) return null; 
    return this.labSession.id;
  }
  getuserId() {
    if(!this.userId) return null; 
    return this.userId;
  }
  getLabName() {
    if(!this.labSession) return 'no exist lab'; 
    return this.labSession.name;
  }
  set_gas(gas){
    this.gas=gas;

}
set_liquid(liquid){
  this.liquid=liquid;

}
set_solid(solid){
    this.solid=solid;

}
get_gas(){
   return this.gas

}
get_liquid(){
return this.liquid

}
get_solid(){
  return this.solid

}


 
};