<div class="ruler row mt-5 mb-3 ml-md-3 mr-md-3 px-md-0 p-0 m-0">
  <form
    class="
      col-12 col-md-9
      d-flex
      justify-content-center justify-content-md-start
      size-small
      align-items-center
      p-md-0 p-2
    "
  >
    <ng-container *ngFor="let input of filterInputs">
      <mat-form-field *ngIf="input.type !== 'select' && input.type !== 'date'&& input.type !== 'multi-select'">
        <mat-label>{{ input.label }}</mat-label>
        <input
          (keyup)="handleChange($event, input)"
          matInput
          type="{{ input.type }}"
          [value]="input.attr.value"
        />
      </mat-form-field>
      <mat-form-field *ngIf="input.type === 'date'">
        <mat-label>{{ input.label }}</mat-label>
        <input
          matInput
          [value]="input.attr.value"
          [matDatepicker]="picker"
          (dateChange)="handleChange($event, input)"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
          <mat-form-field *ngIf="input.type === 'multi-select'">
          <mat-label>{{ input.label }}</mat-label>
          <mat-select  #mySelCategory
            [value]="initialValueFormulti(input.attr.value, input.options)" 
            (selectionChange)="handleChange($event, input)" multiple>
            <!-- <mat-select-trigger>
              {{input.attr.value?.[0] || ''}} -->
              <span *ngIf="(input.attr.value?.length || 0) > 1" class="example-additional-selection">
                (+{{(input.attr.value?.length || 0) - 1}} {{input.attr.value?.length === 2 ? 'other' : 'others'}})
              </span>
            <!-- </mat-select-trigger>  -->
            <mat-option [value]="0" (click)="toggleAllSelection(mySelCategory)"
      >בחר הכל</mat-option
    >
            <mat-option
              *ngFor="let option of input.options" 
              [value]="option.value" 
              >{{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      <mat-form-field *ngIf="input.type === 'select'">
        <mat-label>{{ input.label }}</mat-label>
        <mat-select
          [value]="initialValue(input.attr.value, input.options)"
          (selectionChange)="handleChange($event, input) "
        >
          <mat-option
            *ngFor="let option of input.options"
            [value]="option.value"
          >
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-content></ng-content>

    <button
      *ngIf="searchBtn"
      class="icl-btn dark font-weight-bold size-small"
      mat-raised-button
      (click)="handleSearchBtnClick($event)"
    >
      <span>{{ searchBtn.title }}</span>
    </button>

    <button
      (click)="openExtraFilters($event)"
      *ngIf="extraFilterInputs?.length"
      class="icl-btn grey font-weight-bold"
      mat-stroked-button
    >
      <img
        [ngClass]="{ 'to-close': isExtraFiltersOpen === false }"
        src="./../../../../assets/images/double-arrow.svg"
        alt=""
      />
    </button>

    <button style="left: 20px;"
      (click)="resetAll($event)"
      class="color-dark font-weight-bold p-0"
      mat-button
    >
      <span  class="d-none d-md-block">נקה חיפוש</span>
      <span class="d-md-none d-block">נקה </span>
    </button>
  </form>
  
  <div
    class="
      col-12 col-md-3
      d-flex
      justify-content-md-end justify-content-center
      align-items-center
      p-0
    "
  >

    <button 
      *ngIf="addBtn"
      class="icl-btn dark font-weight-bold size-small"
      mat-raised-button
      (click)="handleBtnClick($event)"
    >
      <span>{{ addBtn.title }}</span>
    </button>
  
  </div>
</div>

<div
  class="ruler row ml-3 mr-3"
  *ngIf="isExtraFiltersOpen && extraFilterInputs && extraFilterInputs.length"
>
  <form class="col-12">
    <div
      class="
        d-flex
        flex-wrap
        justify-content-start
        align-items-center
        size-small
        bg-area
        pt-2
        pl-4
        pr-4
      "
    >
    <div style="flex: 100%">
      <span class="color-darker font-weight-bold">חיפוש מתקדם</span>
    </div>
   
      <ng-container *ngFor="let input of extraFilterInputs">
        <mat-form-field
          *ngIf="input.type !== 'select' && input.type !== 'date' && input.type !== 'multi-select'"
          class="example-full-width"
        >
          <mat-label>{{ input.label }}</mat-label>
          <input
            (keyup)="handleChange($event, input)"
            matInput
            type="{{ input.type }}"
            [value]="input.attr.value"
          />
        </mat-form-field>
        <mat-form-field *ngIf="input.type === 'date'">
          <mat-label>{{ input.label }}</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [value]="input.attr.value"
            (dateChange)="handleChange($event, input)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field *ngIf="input.type === 'multi-select'">
          <mat-label>{{ input.label }}</mat-label>
          <mat-select #mySelCategory
            [value]="initialValueFormulti(input.attr.value, input.options)"
            (selectionChange)="handleChange($event, input)" multiple>
            <!-- <mat-select-trigger>
              {{input.attr.value?.[0] || ''}} -->
              <span *ngIf="(input.attr.value?.length || 0) > 1" class="example-additional-selection">
                (+{{(input.attr.value?.length || 0) - 1}} {{input.attr.value?.length === 2 ? 'other' : 'others'}})
              </span>
            <!-- </mat-select-trigger>  -->
            <mat-option [value]="0" (click)="toggleAllSelection(mySelCategory)"
            >בחר הכל</mat-option
          >
            <mat-option
              *ngFor="let option of input.options"
              [value]="option.value"
              >{{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="input.type === 'select'">
          <mat-label>{{ input.label }}</mat-label>
          <mat-select
            [value]="initialValue(input.attr.value, input.options)"
            (selectionChange)="handleChange($event, input)" >
            <mat-option
              *ngFor="let option of input.options"
              [value]="option.value"
              >{{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <ng-container  *ngFor="let input of filterColums">
        <mat-form-field  >
          <mat-label>{{ input.label }}</mat-label>
          <mat-select (click)=" rest_field()" 
            [value]="initialValueFormulti(input.attr.value, input.options)"
            (selectionChange)="handleChange($event, input) ;filter_col_func(input.attr.value)" multiple>
              <span *ngIf="(input.attr.value?.length || 0) > 1" class="example-additional-selection">
                (+{{(input.attr.value?.length || 0) - 1}} {{input.attr.value?.length === 2 ? 'other' : 'others'}})
              </span>
            <mat-option (click)="get_clicked(matOption)"
              *ngFor="let option of input.options"
              [value]="option.value"  #matOption
              >{{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
     
      
  
         
    
  <!-- </div> -->
      </ng-container>

      <div style="flex: 100%">
        <!-- <select   style="margin-right: -15px;" name="saves" id="Saves" (change)="handleSaves($event.target)" >
          <option disabled='true' value="0" selected="selected">  {{'וריאנט שמור'+' '+this.selectedSave}}</option>
            <option *ngFor="let res of this.saveSearch; index as i " [(value)]="res.save_name" [(id_value)]="res.id" class="optionLabs">
              {{res.save_name}}
          </option>
      </select>  -->
      <!-- <ng-container >
        <mat-form-field  style="margin-right: 15px;"   >
          <mat-label>{{'שם וריאנט לשמירה'}}</mat-label>
          <input (keyup)="onKey($event)"
            matInput
            type="text"
          />
        </mat-form-field> 
      </ng-container>
      <button *ngIf="saveBtn" style="margin-right: 8px;"  (click)="handleSaveBtnClick($event)" class="icl-btn dark font-weight-bold size-small"
      mat-raised-button>
      <span>
        {{ saveBtn.title }}
      </span>
  </button>
    <button  style="margin-right: 5px;"  (click)="setDefaultSearch()" class="icl-btn dark font-weight-bold size-small"
    mat-raised-button>
    <span>
       הגדר וריאנט ברירת מחדל
    </span>
</button> -->
<button *ngIf="saveBtn" style="margin-right: 10px;"  (click)="handleDeleteSaveBtnClick($event)" class="icl-btn dark font-weight-bold size-small"
mat-raised-button>
<span>
  {{ deleteBtn.title }}
</span>
</button>
<button style="margin-left:15px ;left: 15px;"
*ngIf="this.route=='הזמנות'"
class="icl-btn font-weight-bold size-small"
mat-raised-button
(click)="exportToExcel($event)"
>
<span> הורד טבלה לקובץ אקסל</span>
</button>


      </div>
      <div style="height: 30px;">

      </div>
 
    </div>
  </form>
</div>
