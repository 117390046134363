import { Entity } from './Entity';


export class SavedSearches extends Entity {
    userId: number| null;
    labId: number| null;
    categoryId: object| null;
    filters: object| null;
    project: string| null;
    budgetItemId: object| null;
    userName: object| null;
    factoryId: object| null;
    status: object| null;
    numOfOrders: number| null;
    samlingPoint: string| null;
    work_hours: number| null;
    save_name: string;
    example_description:string;
    default_search:number;
    public id!: number| null;

    constructor(obj: Partial<SavedSearches>) {
        super(obj);
    }
}
