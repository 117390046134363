import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SampleService } from 'src/app/globals/services/sample.service';

@Component({
  selector: 'app-download-window',
  templateUrl: './download-window.component.html',
  styleUrls: ['./download-window.component.scss']
})
export class downloadWindow {
  files=[]
  showModal = false;

constructor(    private sampleService: SampleService,
  public dialogRef: MatDialogRef<downloadWindow>,
  @Inject(MAT_DIALOG_DATA) public data: any
) {
  // this.files = data.files ? data.files.split(',') : [];
  console.log(this.files)
  this.files= data.files.split(',')
  .map(file => file.trim())
  .filter(file => file !== '');
}

ngOnInit() {
  // Split the fileString by commas, trim whitespace and filter out empty strings

}

openModal(): void {
  this.showModal = true;
}

closeModal(): void {
  this.dialogRef.close();
}
openFile(file: string): void {
  this.sampleService.getSpesificExcel(file);
  // For demonstration, open the file in a new browser tab.
  // Ensure that file is a valid URL or adjust the behavior accordingly.
}
}
