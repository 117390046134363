import { Injectable } from '@angular/core';
import { Analyze } from '../base/Analyze';
import { IclLabsAPIService } from './icl-labs.api.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyzeService {  
  constructor(private iclLabsApiService: IclLabsAPIService, private sessionService : SessionService ) {}

  async getAnalyzes(): Promise<Analyze[]> {
    try {
      let analyzes: Analyze[] = [];
      const params = {labId : this.sessionService.getLabId()};
      const result = await this.iclLabsApiService.get('/analyze',{ params });
      if (result && result.analyzes) {
        result.analyzes.map((analyze) => analyzes.push(new Analyze(analyze)));
      }
      return analyzes;
    } catch (err) {
      console.error(err);
    }
  }

  async createAnalyze(analyze: Partial<Analyze>): Promise<Analyze> {
    try {
      const labId = this.sessionService.getLabId();
      analyze["labId"] = labId;
      const result = await this.iclLabsApiService.post('/analyze', {
        body: analyze,
      });
      if (result && result.analyze) {
        return new Analyze(result.analyze);
      } else {
        throw new Error('something went wrong');
      }
    } catch (err) {
      console.error(err);
    }
  }

  async editAnalyze(id: number, analyze: Partial<Analyze>): Promise<boolean> {
    try {
      const result = await this.iclLabsApiService.put('/analyze/' + id, {
        body: analyze,
      });
      if (result && result.success) {
        return result.success;
      }
      return false;
    } catch (err) {
      console.error(err);
    }
  }

  async deleteAnalyze(id: number, params?: any): Promise<boolean> {
    try {
      const result = await this.iclLabsApiService.delete('/analyze/' + id, {
        params,
      });
      if (result && result.success) {
        return result.success;
      }
      return false;
    } catch (err) {
      console.error(err);
    }
  }
}
