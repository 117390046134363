import { Injectable } from '@angular/core';
import { IclLabsAPIService } from './icl-labs.api.service';
import { SessionService } from './session.service';
import { MailList } from '../base/MailList';
import { User } from '../base/User';

@Injectable({
  providedIn: 'root',
})
export class MailListService {
  user: User;
  constructor(private iclLabsApiService: IclLabsAPIService, private sessionService : SessionService ) {}

 
  async createMailsList(mailList: Partial<MailList>): Promise<MailList> {
    try {
      const result = await this.iclLabsApiService.post('/MailList', {
        body: mailList,
      });
      if (result && result.mailList) {
        return new MailList(result.mailList);
      } else {
        throw new Error('something went wrong');
      }
    } catch (err) {
      throw new Error(err);
    }
  }
  async getsavedLists(): Promise<MailList[]> {

    try {
      let mailList: MailList[] = [];
      const params = { labId: this.sessionService.getLabId()};
      
      const result = await this.iclLabsApiService.get('/MailList', { params });
    
      if (result && result.mailList) {
        //  result.mailList.map((mailList) => mailList.push(new MailList(mailList)));
      }
      // this.mailList.next(result);
      return result['mailList'];
    } catch (err) {
      console.error(err);
    }
  }
  async editMailList(id: number, mailList: Partial<MailList>): Promise<boolean> {
    try {
      const result = await this.iclLabsApiService.put('/MailList/' + id, {
        body: mailList,
      });
      if (result && result.success) {
        return result.success;
      }
      return false;
    } catch (err) {
      console.error(err);
    }
  }

  async deleteMailList(id: number, params?: any): Promise<boolean> {
    try {
      const result = await this.iclLabsApiService.delete('/MailList/' + id, {
        params,
      });
      if (result && result.success) {
        return result.success;
      }
      return false;
    } catch (err) {
      console.error(err);
    }
  }
}

