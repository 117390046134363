import { Injectable } from '@angular/core';
import { Sample } from '../base/Sample';
import { IclLabsAPIService } from './icl-labs.api.service';
import { saveAs } from 'file-saver';
import { SessionService } from './session.service';

@Injectable({ providedIn: 'root' })
export class SampleService {
  cunter_index=0;
  constructor(private iclLabsApiService: IclLabsAPIService, private sessionService: SessionService) {}

  async deleteSample(id: number) {
    try {
      let result;
      result = await this.iclLabsApiService.delete('/sample/' + id, {});
      return result;
    } catch (err) {
      console.error(err);
    }
  }

  async getExcel(excel: string) {
    try {
      const excel_array=excel.split(',');
      excel_array.forEach(async excel_file => {
        if(excel_file!=''){
          const result = await this.iclLabsApiService.getData(
            '/sample/file/' + excel_file
          ); 
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          const blob = new Blob([result]);
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          var fileExtention = excel_file.split('.')[1];
          fileExtention = fileExtention.toLowerCase();
          a.download = excel_file 
          a.click();
    
        }
        
      });
    
    } catch (err) {
      console.error(err);
    }
  }

  async search(attrs: string): Promise<Sample[]> {
    try {
      const labId = this.sessionService.getLabId()
      const attrLab = `&labId=${labId}`; 
      attrs+=attrLab;
      let samples: Sample[] = [];
      const result = await this.iclLabsApiService.get('/sample' + attrs);
      if (result.samples)
        samples = result.samples.map((sample) => new Sample(sample));
      return samples;
    } catch (err) {
      console.error(err);
    }
  }

  async getSample(id: number): Promise<Sample> {
    try {
      let sample: Sample;
      const result = await this.iclLabsApiService.get('/sample/' + id);
      if (result.sample) sample = new Sample(result.sample);
      return sample;
    } catch (err) {
      console.error(err);
    }
  }

  async getSamples(): Promise<Sample[]> {
    try {
      let samples: Sample[] = [];
      const params = {labId : this.sessionService.getLabId()};
      const result = await this.iclLabsApiService.get('/sample', {params});
      if (result.samples)
        samples = result.samples.map((sample) => new Sample(sample));
      return samples;
    } catch (err) {
      console.error(err);
    }
  }

  async postSample(sample: Sample): Promise<Sample> {
    try {
      const labId = this.sessionService.getLabId();
      sample.setLabId(labId);
      const result = await this.iclLabsApiService.post('/sample', {
        body: this.convertToPostRequest(sample),
      });
      console.log(result);
      if (result.sample) return result.sample;
      return null;
    } catch (err) {
      console.error(err);
    }
  }

  async postExcel(id: number, files: Array<File>): Promise<string> {
    return new Promise((resolve,reject)=>{
      let filesName=''
      try {
        files.forEach(async (file,index) => {
          const res = await this.iclLabsApiService.postData(
            '/sample/' + id + '/excel',
            [{ key: 'file', files:[file]}]
            // [{ key: 'file', files: [file[0],file[1],file[2]]}]
          );
          
          if (res && res.fileName) {
            filesName=filesName+res.fileName+',' ;
          }
          this.cunter_index++
          if(this.cunter_index==files.length) {
            console.log(filesName)
            this.cunter_index=0;
            resolve(filesName);
          }
        });
        
       
        
       
           
      } 
      catch (err) {
        console.error(err);
      }
    })
  }

  async putSample(
    sample: Partial<Sample>,
    id: number
  ): Promise<{ success: boolean }> {
    try {
      const result = await this.iclLabsApiService.put('/sample/' + id, {
        body: sample,
      });
      console.log(result);
      return result;
    } catch (err) {
      console.error(err);
    }
  }

  private convertToPostRequest(sample: Sample): SamplePostRequest {
    return {
      number: sample.number,
      project: sample.project,
      subject: sample.subject,
      samlingPoint: sample.samlingPoint,
      suspention: sample.suspention,
      solid: sample.solid,
      solution: sample.solution,
      analyzesCustom: sample.analyzesCustom,
      cordinationLaboratory: sample.cordinationLaboratory,
      aspect: sample.aspect,
      aspects: sample.aspects,
      status: sample.status,
      excel: sample.excel,
      expectResultsDate: sample.expectResultsDate,
      factoryId: sample.factoryId,
      categoryId: sample.categoryId,
      budgetItemId: sample.budgetItemId,
      userId: sample.userId,
      userName: sample.userName,
      aspectsCustom: sample.aspectsCustom,
      analyzes: sample.analyzes,
      emailsString: sample.emailsString,
      numOfOrders: sample.numOfOrders,
      labId: sample.labId,
      gas_exampels: sample.gas_exampels,
      liquid_exampels: sample.liquid_exampels,
      solid_exampels: sample.solid_exampels,
      example_description: sample.example_description,
      comments: sample.comments,
      preference: sample.preference,
      work_hours: sample.work_hours
    };
  }
}

export interface SamplePostRequest {
  number: number;
  project: string;
  subject: string;
  samlingPoint: string;
  userName: string;
  suspention: number;
  solid: number;
  solution: number;
  analyzesCustom: string;
  cordinationLaboratory: string;
  aspect: boolean;
  aspects: number[];
  aspectsCustom: string;
  emailsString: string;
  status: number;
  excel: string;
  expectResultsDate: any;
  factoryId: number;
  categoryId: number;
  budgetItemId: number;
  userId: number;
  analyzes: number[];
  numOfOrders: number;
  labId: number;
  gas_exampels: object| null;
  liquid_exampels: object| null;
  example_description: string;
  solid_exampels: object| null;
  comments: string;
  preference: string;
  work_hours: number;
}

export interface SamplePostResponse extends SamplePostRequest {
  id: number;
  updatedAt: string;
  createdAt: string;
}
