import { Entity } from './Entity';

export class Analyze extends Entity {

    id: number;
    name: string;
    description: string;
    work_hours: number ;
    labId : number;
    constructor(args: Partial<Analyze>) {
        super(args);
    }
    setLabId(labId){
        this.labId = labId
    }
}