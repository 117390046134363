import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, Output, EventEmitter, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialsModule } from 'src/app/materials.module';
import { SessionService } from '../../services/session.service';

@Component({
    templateUrl: './matter-create.component.html',
    styleUrls: ['./matter-create.component.scss'],
    selector: 'global-matter-create'
})


export class MatterCreateComponent  implements OnInit  {
   
    @Input() matters: MatterInput[];
    @Output() mattersChange = new EventEmitter()

    @Input() disabled : boolean = false;
    public gas=[];
    public liquid=[];
    public solid=[];
    input: any;
    controlName='new';

    gDynamicForm!: FormGroup;
    lDynamicForm!: FormGroup;
    sDynamicForm!: FormGroup;
    flag = false;
    gDynamicArr=[];
    lDynamicArr=[];
    sDynamicArr=[];
    index: any;
    currentLabId: number;
    constructor(private formBuilder: FormBuilder, private sessionService: SessionService) {
        this.currentLabId=sessionService.getLabId();
        this.solid=[]
        this.gas=[]
        this.liquid=[]
        console.log( this.currentLabId)
        this.gDynamicForm = this.formBuilder.group({
          
        });
        this.lDynamicForm = this.formBuilder.group({
          
        });
        this.sDynamicForm = this.formBuilder.group({
          
        });
        this.sessionService.set_solid(this.solid);
        this.sessionService.set_gas(this.gas);
        this.sessionService.set_liquid(this.liquid);
        this.gDynamicForm.reset();
        this.lDynamicForm.reset();
        this.sDynamicForm.reset();
       
       
   
      }
          ngOnInit() {
         
            this.currentLabId=this.sessionService.getLabId();
        this.gDynamicForm = this.formBuilder.group({
          
        });
        this.lDynamicForm = this.formBuilder.group({
          
        });
        this.sDynamicForm = this.formBuilder.group({
          
        });
    }
 
    


    onMatterChange(index) {
        
        if(index!=undefined)
        this.index=index;
        if(index !== undefined && !this.matters[index].activate)
            this.matters[index].amount = 0;
        this.mattersChange.emit(this.matters);
        if(this.currentLabId==1){
        if(this.matters[this.index].type==0 && this.matters[this.index].activate==true){
        this.gDynamicArr.length=this.matters[matterType.gas].amount;
        this.gas.length=this.matters[matterType.gas].amount;
        }
        if(this.matters[this.index].type==1 && this.matters[this.index].activate==true){
        this.lDynamicArr.length=this.matters[matterType.liquid].amount;
        this.liquid.length=this.matters[matterType.liquid].amount;
        }
        if(this.matters[this.index].type==2 && this.matters[this.index].activate==true){
        this.sDynamicArr.length=this.matters[matterType.solid].amount;
        this.solid.length=this.matters[matterType.solid].amount;
        }
    }
    if(this.currentLabId==3){
        if(this.matters[this.index].type==0 && this.matters[this.index].activate==true){
        this.gDynamicArr.length=this.matters[matterType.gas].amount;
        this.gas.length=this.matters[matterType.gas].amount;
        }
        if(this.matters[this.index].type==1 && this.matters[this.index].activate==true){
        this.lDynamicArr.length=this.matters[matterType.liquid].amount;
        this.liquid.length=this.matters[matterType.liquid].amount;
        }
        if(this.matters[this.index].type==2 && this.matters[this.index].activate==true){
        this.sDynamicArr.length=this.matters[matterType.solid].amount;
        this.solid.length=this.matters[matterType.solid].amount;
        }
    }
    }
    setFormLength(amount){
        if(this.index==0){
            for (let i = 0; i < amount; i++) {
                this.controlName='newG'+i
                this.gDynamicForm.addControl(this.controlName, new FormControl(''));
              }
              this.flag=true;
        }
        if(this.index==1){
            for (let i = 0; i < amount; i++) {
                this.controlName='newL'+i
                this.lDynamicForm.addControl(this.controlName, new FormControl(''));
              }
              this.flag=true;
        }
        if(this.index==2){
            for (let i = 0; i < amount; i++) {
                this.controlName='newS'+i
                this.sDynamicForm.addControl(this.controlName, new FormControl(''));
              }
              this.flag=true;
        }
      

    }
    onChangeTextInG(y){
        const GdynamicName='newG'+y;
        this.gas[y]=this.gDynamicForm.get(GdynamicName).value;
            console.log( this.gas[y]);
            this.sessionService.set_gas(this.gas);
      
    }
    onChangeTextInL(y){
        const LdynamicName='newL'+y;
        this.liquid[y]=this.lDynamicForm.get(LdynamicName).value;
        console.log( this.liquid[y]);
        this.sessionService.set_liquid(this.liquid);
      
    }
    onChangeTextInS(y){
        const SdynamicName='newS'+y;
        this.solid[y]=this.sDynamicForm.get(SdynamicName).value;
        console.log( this.solid[y]);
        this.sessionService.set_solid(this.solid);
      
    }


    getNameOfType(matter:MatterInput): string {
        let name: string = ''
        switch (matter.type) {
            case 0:
                name = 'תרחיף'
                break;
            case 1:
                name = 'נוזל'
                break;
            case 2:
                name = 'מוצק'
                break;
        }
        return name;
    }

}

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MaterialsModule,
        FormsModule,
        ReactiveFormsModule,
        
    ],
    exports: [
        MatterCreateComponent
    ],
    declarations: [
        MatterCreateComponent
    ]
})

export class MatterModule2 { }

export interface MatterInput {
    type: number;
    amount: number;
    activate: boolean;
}

export enum matterType {
    gas = 0,
    liquid = 1,
    solid = 2
}