import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class LoaderService {

    private status = new BehaviorSubject<boolean>(false);
    public status$ = this.status.asObservable();

    setStatus(status: boolean) {
        this.status.next(status)
    }
}