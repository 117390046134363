import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatOption,
} from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import {
  MomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { savedSearchesService } from 'src/app/globals/services/savedSearches.service';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialsModule } from 'src/app/materials.module';
import { Attr, SamplesTableComponent } from 'src/app/modules/icl-labs/routes/samples-table/samples-table.component';
import { Router, RouterModule } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppHeaderComponent } from '../header/header.component';
import { SessionService } from '../../services/session.service';
import { Status, statuses } from 'src/app/globals/base/Status';
import * as XLSX from 'xlsx';
import { HeaderService } from '../../services/header.service';
import { FormControl } from '@angular/forms';



const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'global-ruler',
  templateUrl: './ruler.component.html',
  styleUrls: ['./ruler.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RulerComponent {
  saveSearch: [];
  selectedSave: string = '';
  selected_attr;
  switchlab;
  saveSearchSdom: [];
  result;
  displayedColumns: { key: string, value: string }[] = []
  id_value: any;
  labId: number;
  
  data_exel1= [{}];
  res_filter: any;
  route: string;
  fromDate: Date;
  pastDate: number;
  counter: any;
 
 
  
  
  constructor(private dateAdapter: DateAdapter<any>, private router: Router, private SamplesTable: SamplesTableComponent,
    private savedSearchesService: savedSearchesService,private seesionS:SessionService,route:HeaderService  ) {
    dateAdapter.setLocale('he');
  //  this.savedSearchesService.savesearch$.subscribe(res=>{
  //   this.saveSearch = res;
  //   console.log(this.saveSearch)
  //   this.saveSearch.forEach(x => {
  //     if(x['default_search']==1)
  //     {
  //       this.id_value=x['id']
  //       this.SamplesTable.handleSaves( x['save_name'],this.saveSearch);
  //     }
  //     localStorage.setItem('default','yes');
  
  //    });
     
    
  //  })
  //  this.seesionS.selectedSave$.subscribe(res=>{
  //   this.selectedSave = res;
  //  })
   this.route=localStorage.getItem('route');
 
  //  this.savedSearchesService.savedFilters$.subscribe(res=>{
  //    this.filter_col_func();
  //  })
   
   
  
  
  }
  async ngOnInit() {  
    this.fromDate = new Date();
        this.pastDate = this.fromDate.getDate() - 30;
        this.fromDate.setDate(this.pastDate);
   
   
   

    //   var result= await this.savedSearchesService.getsavedSearche();
    //  this.saveSearch=result['Savedsearches'];
           }

  //Filters will come from input
  @Input() filterInputs: FilterInput[];
  @Input() extraFilterInputs: FilterInput[];
  @Input() filterColums: FilterInput[];
  @Input() saveName: SaveName[];
  @Input() addBtn: AddBtn;
  @Input() searchBtn: AddBtn;
  @Input() saveBtn: SaveBtn;
  @Input() deleteBtn: SaveBtn;
  @Input() isExtraFiltersOpen: boolean = false;
 

  @Output() reset = new EventEmitter();
  save_name: string ;
  clickMessage = '';
  value = '';
 
  openExtraFilters(e: Event) {
    e.preventDefault();
    this.isExtraFiltersOpen = !this.isExtraFiltersOpen;
  }

  handleBtnClick(e: Event) {
    e.preventDefault();
    if (this.addBtn && this.addBtn.fn) this.addBtn.fn();
  }
  handleSaveBtnClick(e: Event) {
    
    e.preventDefault();
    localStorage.setItem('save_name',this.value)
   var result = confirm("האם ברצונך לשמור וריאנט?");
   if (result) {
    if (this.saveBtn && this.saveBtn.fn) this.saveBtn.fn();
   }
    
  }
  
  allSelected = false;

  branch = new FormControl();
  category = new FormControl();

  branchList: string[] = ["London", "Manchester", "Newcastle"];
  categoryList: string[] = ["Category A", "Category B", "Category C"];

  toggleAllSelection(matSelect: MatSelect) {
    const isSelected: boolean = matSelect.options
      // The "Select All" item has the value 0
      .filter((item: MatOption) => item.value === 0)
      // Get the selected property (this tells us whether Select All is selected or not)
      .map((item: MatOption) => item.selected)[0];
    // Get the first element (there should only be 1 option with the value 0 in the select)

    if (isSelected) {
      matSelect.options.forEach((item: MatOption) => item.select());
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }
  onKey(event: any) { // without type info
    this.value = event.target.value;
  }
  restselectField(){
    this.selectedSave='';
}
  handleSearchBtnClick(e: Event) {
    e.preventDefault();
    if (this.searchBtn && this.searchBtn.fn) this.searchBtn.fn();
  }
//     handleSaves(event) {
//       localStorage.setItem('default','no');
//     this.SamplesTable.resetAll();
//     this.id_value=event['selectedOptions']['0'].id_value;
//     this.selectedSave=event.value;
//      this.SamplesTable.handleSaves( this.selectedSave,this.saveSearch);
// }
exportToExcel(){
  this.res_filter=this.SamplesTable.get_displayedColumns();
  console.log(this.res_filter);
  let res=this.SamplesTable.get_sample_table();
  if(this.res_filter!=undefined){
    this.counter=this.res_filter.length
    for(let counter=this.counter;counter>0;counter--){
      this.data_exel1[0][this.res_filter[counter-1].value]=res['filteredData'][0][this.res_filter[counter-1].key];
      }
  }
  else{
    if(this.labId==1||this.labId==3)
    {
      this.data_exel1[0]['שם פרויקט']=res['filteredData'][0].project;
      this.data_exel1[0]['סעיף תקציבי']=res['filteredData'][0].BudgetItem;
      this.data_exel1[0]['שם הלקוח']=res['filteredData'][0].client_name;
      this.data_exel1[0]['מפעל']=res['filteredData'][0].factory;
      this.data_exel1[0]['מזהה הזמנה']=res['filteredData'][0].id;
      this.data_exel1[0]['סטטוס נוכחי']=statuses[res['filteredData'][0].res_status].title;
      this.data_exel1[0]['מועד שינוי הסטטוס']=res['filteredData'][0].status_change_time.label;
      this.data_exel1[0]['מבצע שינוי סטטוס נוכחי']=res['filteredData'][0].changed_by;
      this.data_exel1[0]['קטגוריה']=res['filteredData'][0].priority;
      this.data_exel1[0]['מס דוגמאות']=res['filteredData'][0].amount;
      this.data_exel1[0]['עדיפות']=res['filteredData'][0].preference;
    }
    else{
      this.data_exel1[0]['מזהה הזמנה']=res['filteredData'][0].id;
      this.data_exel1[0]['שם פרויקט']=res['filteredData'][0].project;
      this.data_exel1[0]['תאריך יצירת הזמנה']=res['filteredData'][0].creation_date.label;
      this.data_exel1[0]['סעיף תקציבי']=res['filteredData'][0].BudgetItem;
      this.data_exel1[0]['מס דוגמאות']=res['filteredData'][0].amount;
      this.data_exel1[0]['שם הלקוח']=res['filteredData'][0].client_name;
      this.data_exel1[0]['מפעל']=res['filteredData'][0].factory;
      this.data_exel1[0]['קטגוריה']=res['filteredData'][0].priority;
      this.data_exel1[0]['סטטוס נוכחי']=statuses[res['filteredData'][0].res_status].title;
      this.data_exel1[0]['מועד שינוי הסטטוס']=res['filteredData'][0].status_change_time.label;
      this.data_exel1[0]['מבצע שינוי סטטוס נוכחי']=res['filteredData'][0].changed_by;
      this.data_exel1[0]['עדיפות']=res['filteredData'][0].preference;

    }
 
  }
 
  let ws1: XLSX.WorkSheet =XLSX.utils.json_to_sheet(this.data_exel1);

  for(let i=1;i<res['filteredData'].length;i++){
    if(this.res_filter!=undefined){
      this.counter=this.res_filter.length
      for(let counter=this.counter;counter>0;counter--){
        this.data_exel1[0][this.res_filter[counter-1].value]=res['filteredData'][i][this.res_filter[counter-1].key];
        }
    }
    else{
      if(this.labId==1||this.labId==3)
    {
      this.data_exel1[0]['שם פרויקט']=res['filteredData'][i].project;
      this.data_exel1[0]['סעיף תקציבי']=res['filteredData'][i].BudgetItem;
      this.data_exel1[0]['שם הלקוח']=res['filteredData'][i].client_name;
      this.data_exel1[0]['מפעל']=res['filteredData'][i].factory;
      this.data_exel1[0]['מזהה הזמנה']=res['filteredData'][i].id;
      this.data_exel1[0]['סטטוס נוכחי']=statuses[res['filteredData'][i].res_status].title;
      this.data_exel1[0]['מועד שינוי הסטטוס']=res['filteredData'][i].status_change_time.label;
      this.data_exel1[0]['מבצע שינוי סטטוס נוכחי']=res['filteredData'][i].changed_by;
      this.data_exel1[0]['קטגוריה']=res['filteredData'][i].priority;
      this.data_exel1[0]['מס דוגמאות']=res['filteredData'][i].amount;
      this.data_exel1[0]['עדיפות']=res['filteredData'][i].preference;
    }
    else{
   
      this.data_exel1[0]['מזהה הזמנה']=res['filteredData'][i].id;
      this.data_exel1[0]['שם פרויקט']=res['filteredData'][i].project;
      this.data_exel1[0]['תאריך יצירת הזמנה']=res['filteredData'][i].creation_date.label;
      this.data_exel1[0]['סעיף תקציבי']=res['filteredData'][i].BudgetItem;
      this.data_exel1[0]['מס דוגמאות']=res['filteredData'][i].amount;
      this.data_exel1[0]['שם הלקוח']=res['filteredData'][i].client_name;
      this.data_exel1[0]['מפעל']=res['filteredData'][i].factory;
      this.data_exel1[0]['קטגוריה']=res['filteredData'][i].priority;
      this.data_exel1[0]['סטטוס נוכחי']=statuses[res['filteredData'][i].res_status].title;
      this.data_exel1[0]['מועד שינוי הסטטוס']=res['filteredData'][i].status_change_time.label;
      this.data_exel1[0]['מבצע שינוי סטטוס נוכחי']=res['filteredData'][i].changed_by;
      this.data_exel1[0]['עדיפות']=res['filteredData'][i].preference;
    }
 
    }
 
    XLSX.utils.sheet_add_json(ws1, this.data_exel1, {origin: -1, skipHeader: true});

   
    
  }

  //  ws1 =XLSX.utils.json_to_sheet(this.data_exel1);
  const sheet1=XLSX.utils.sheet_to_json(ws1);
  let wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws1, 'Report');
  XLSX.writeFile(wb, 'Report.xlsx');

}

// handleDeleteSaveBtnClick(){
//   var result = confirm("בטוח שברצונך למחוק את הוריאנט השמור?");
// if (result) {
//   this.SamplesTable.deleteSaveSample( this.id_value);
// }
  
// }
// filter_col_func(value){
//   console.log(value)
//   this.SamplesTable.filter_col_func(value)
// }
  async setDefaultSearch(){
    let temp_saved_search=this.saveSearch
    this.labId=this.seesionS.getLabId();
    temp_saved_search["currentLab"] = this.labId;
  await this.savedSearchesService.setDefaultSearch( this.id_value,temp_saved_search);
}

  handleChange(e, input: FilterInput) {
    if (e instanceof MatDatepickerInputEvent) {
      if (e.target.value) {
        input.attr.value = new Date(e.target.value).toISOString();
        input.fn(input);
      }
    } else if (e instanceof MatSelectChange) {
      input.attr.value = Number.isInteger(e.value)
        ? JSON.stringify(e.value)
        : e.value;
      input.fn(input);
    } else if (e.target && input.fn) {
      input.attr.value = Number.isInteger(e.target.value)
        ? JSON.stringify(e.target.value)
        : e.target.value;
      input.fn(input);
    }
  }

  initialValue(value: any, options: Option[]) {
    if (value) {
      if (this.areOptionsIntegers(options)) {
        if (!Number.isInteger(value)) {
          return JSON.parse(value);
        } else {
          return value;
        }
      } else {
        return value;
      }
    }
    return undefined;
  }
  initialValueFormulti(value: [], options: Option[]) {
   
    if (value) {
      if (this.areOptionsIntegers(options)) {
        if (!Number.isInteger(value)) {
          return (value);
        } else {
          return value;
        }
      } else {
        return value;
      }
    }
    return undefined;
  }
  get_clicked(value){
    this.SamplesTable.get_clicked(value.value);
  }

  areOptionsIntegers(options: Option[]): boolean {
    try {
      return Number.isInteger(options[0].value);
    } catch (err) {
      console.error(err);
    }
  }
  rest_field(){
    this.SamplesTable.rest_field();
  }
  public resetAll(e: Event) {
    this.selectedSave='';
    console.log(this.filterInputs);

    e.preventDefault();
    this.reset.emit();
  }
}

@NgModule({
  imports: [CommonModule, TranslateModule, MaterialsModule],
  exports: [RulerComponent],
  declarations: [RulerComponent],
})
export class RulerModule {}

export interface FilterInput {
  type: string;
  label: string;
  fn: Function;
  attr: Attr;
  options?: Option[];
}
export interface Option {
  value: string | number;
  label: string;
  current?: string | number;
}
export interface AddBtn {
  title: string;
  fn: Function;
}
export interface SearchBtn {
  title: string;
  fn: Function;
}
export interface SaveBtn {
  title: string;
  fn: Function;
}
export interface DeleteBtn {
  title: string;
  fn: Function;
}

export interface SaveName {
  type: string;
  label: string;
  fn: Function;
  attr: Attr;
  options?: Option[];
}

