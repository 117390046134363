import { Entity } from './Entity';

export class MailList extends Entity {

    id: number;
    list_name: string;
    labId: number;
    emailsString:string;

    constructor(args: Partial<MailList>) {
        super(args);
    }
}