<div class="container" *ngIf="user">
    <div class="d-none d-md-block table-wrapper mt-5">
        <global-ruler (reset)="resetAll()" [searchBtn]="searchBtn" [deleteBtn]="deleteBtn" [saveBtn]="saveBtn" [addBtn]="addBtn" [filterInputs]="filterInputs"
            [extraFilterInputs]="extraFilterInputs" [isExtraFiltersOpen]="isExtraFiltersOpen" [saveName]="saveName" [filterColums]="filterColums">
        </global-ruler>
        <global-table  *ngIf="dataSource && notEmpty;else noResults" (sortChange)="handleTableSortChange($event)"
            [isPointer]="true" [(pageIndex)]="pageIndex" [saveCurrentPage]="true" [rowDownload]="rowDownload"  [rowAction]="rowAction" [initialSort]="tableSort" [displayedColumns]="displayedColumns" [dataSource]="dataSource">
        </global-table>
        <ng-template #noResults>
            <div class="no-results mt-5">לא נמצאו תוצאות</div>
        </ng-template>
    </div>
    <div class="d-block d-md-none accordion-wrapper">
        <div class="row my-5 mx-0 px-4 justify-content-between">
            <button (click)="toggleFilterModal()" class="icl-btn ghost font-weight-bold" mat-stroked-button>
                <span>
                    חיפוש
                </span>
            </button>
            <button *ngIf="canViewSampleCreate()" (click)="addBtn.fn()" class="icl-btn dark font-weight-bold size-small"
                mat-raised-button>
                <span>
                    צור הזמנה
                </span>
            </button>
            <!-- <button  (click)="addBtn2.fn()" class="icl-btn dark font-weight-bold size-small"
            mat-raised-button>
            <span>
                שמור
            </span>
        </button> -->
        </div>
        <div class="mobile-table-header d-flex px-2 py-3 font-weight-bold">
            <div class="row justify-content-between align-items-center m-0 p-0 w-100 ">
                <div (click)="sortBy('project')" class="col-4 p-0">
                    שם פרויקט
                </div>
                <div (click)="sortBy('status_change_time')" class="col-5 p-0">
                    תאריך ושעה
                </div>
            </div>
        </div>
        <global-accordion *ngIf="accordionData && notEmpty;else noResults" [accordionData]="accordionData"
            [type]="'light'" [markup]="samplesMarkUp"></global-accordion>
        <ng-template #noResults>
            <div class="no-results mt-5">לא נמצאו תוצאות</div>
        </ng-template>
    </div>
</div>


<!-- THIS IS JUST TEMPLATE TO BE INJECT INTO GLOBAL-ACCORDION -->
<ng-template #samplesMarkUp let-header="header">
    <div class="row justify-content-between align-items-center m-0 p-0 w-100">
        <div class="col-4 p-0 font-weight-bold"
            style="overflow: hidden; overflow-wrap: inherit; text-overflow: ellipsis; white-space: nowrap;">
            {{header.project}}
        </div>
        <div class="col-2 status-table p-0">
            <div class="color-line bg-{{header.status}}"></div>
        </div>
        <div class="col-6 p-0" [innerHtml]="header.time"></div>
    </div>
</ng-template>
<!-- THIS IS JUST TEMPLATE TO BE INJECT INTO GLOBAL-ACCORDION -->

<global-modal [(isOpen)]="isFilterOpen" *ngIf="filterInputs && extraFilterInputs">
    <div class="row p-0 align-items-center justify-content-between">
        <div class="col-12">
            <h4 class="size-small-normal font-weight-bold">חיפוש מתקדם</h4>
        </div>
        <div class="row">
            <ng-container *ngFor="let input of filterInputs">
                <div class="col-6">
                    <mat-form-field *ngIf="input.type !== 'select' && input.type !== 'date'">
                        <mat-label>{{input.label}}</mat-label>
                       
                        <input (keyup)="handleChange($event, input)" matInput type="{{input.type}}" placeholder=""
                            [value]="input.attr.value">
                    </mat-form-field>
                    <mat-form-field *ngIf="input.type === 'date'">
                        <mat-label>{{input.label}}</mat-label>
                        <input matInput [value]="input.attr.value" [matDatepicker]="picker"
                            (dateChange)="handleChange($event,input)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field *ngIf="input.type === 'select'">
                        <mat-label>{{input.label}}</mat-label>
                        <mat-select (selectionChange)="handleChange($event, input)"
                            [value]="initialValue(input.attr.value , input.options)">
                            <mat-option *ngFor="let option of input.options" [value]="option.value">{{option.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>
        </div>
        <div class="row">
            <ng-container *ngFor="let input of extraFilterInputs">
                <div class="col-6">
                    <mat-form-field   *ngIf="input.type !== 'select' && input.type !== 'date'&& input.type !== 'multi-select'" class="example-full-width">
                        <mat-label>{{input.label}}</mat-label>
                        <input (keyup)="handleChange($event, input)" matInput type="{{input.type}}"
                            [value]="input.attr.value">
                    </mat-form-field>
                    <mat-form-field *ngIf="input.type === 'date'">
                        <mat-label>{{input.label}}</mat-label>
                        <input [value]="input.attr.value" matInput [matDatepicker]="picker"
                            (dateChange)="handleChange($event,input)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field *ngIf="input.type === 'select'">
                        <mat-label>{{input.label}}</mat-label>
                        <mat-select (selectionChange)="handleChange($event, input)"
                            [value]="initialValue(input.attr.value , input.options)">
                            <mat-option *ngFor="let option of input.options" [value]="option.value">{{option.label}}
                        
                            </mat-option>
                      
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>
        </div>
        <div class="col-12 d-flex justify-content-end mt-4">
            <!-- <button class="color-dark font-weight-bold" mat-button (click)="resetAll()">
                <span>נקה חיפוש</span>
            </button> -->
            <button (click)="searchSample()" class="icl-btn ghost font-weight-bold" mat-stroked-button>
                <span class="d-inline-flex">
                    <img src="assets/images/search.png" alt="" width="30">
                </span>
            </button>
        </div>
    </div>
</global-modal>