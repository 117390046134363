import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Analyze } from 'src/app/globals/base/Analyze';
import { Aspect } from 'src/app/globals/base/Aspect';
import { BudgetItem } from 'src/app/globals/base/BudgetItem';
import { Category } from 'src/app/globals/base/Category';
import { IclRoute } from 'src/app/globals/base/IclRoute';
import { Sample } from 'src/app/globals/base/Sample';
import { Status, statuses, STATUS_CONST } from 'src/app/globals/base/Status';
import { AnalyzesSection } from '../../components/analyzes/analyzes.component';
import { DangersSection } from '../../components/dangers/dangers.component';
import { IclLabsService } from '../../icl-labs.service';
import { MatterInput, matterType } from "./../../../../globals/components/matter/matter.component";
import { Router } from '@angular/router';
import { User } from "src/app/globals/base/User";
import { Observable, Subscriber } from "rxjs";
import { SessionService } from "src/app/globals/services/session.service";
import { map, startWith } from "rxjs/operators";
import { CanComponentDeactivate } from "../../icl-labs.module";
import { PermissionsService } from "src/app/globals/services/permissions.service";
import { MatDialog } from '@angular/material/dialog';

import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { ENTER } from "@angular/cdk/keycodes";
import { MailListService } from "src/app/globals/services/mail-list.service";
import { MailList } from "src/app/globals/base/MailList";
const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
    }
}
@Component({
    selector: 'lab-sample-edit',
    styleUrls: ['./sample-edit.component.scss'],
    templateUrl: 'sample-edit.component.html',
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})

export class SampleEditComponent implements OnInit, OnDestroy, IclRoute, CanComponentDeactivate {


    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    public routeName: string = "עריכת הזמנה";
    public uploadFileError: string = "";
    public _statuses = statuses.slice();
    public isDeletedModal: boolean = false;
    public user: User;
    public saved: boolean = false;
    public updateBtnNotActive: boolean = false;
    subscriber = new Subscriber();
    controlName: any;
    gDynamicForm!: FormGroup;
    lDynamicForm!: FormGroup;
    sDynamicForm!: FormGroup;
    flag: boolean;
    work_temp: number;
    preferences =[ {'id': 1, 'name': 'דחוף'},{'id': 2, 'name': 'גבוהה'},{'id': 3, 'name': 'בינוני'},{'id': 4, 'name': 'נמוך'}];
    saved_Mails: any[];
    lists_name: any[];
    saved_mails: any;
    selected_lists: string;
    cunclsion: number;
    budget_num: BudgetItem;
    canSeeDownload=false
    reasultArray: any;
    uploadedFile=false
    fileArray: string[];

    get analyzesValid(): boolean {
        if (this.analyzesSection) {
            return this.analyzesSection.chosenAnalyzes.length > 0 || this.analyzesSection.customAnalyzes.length > 0;
        }
    }

    get mattersValid(): boolean {
        if (this.dangersSection)
            if (this.dangersSection.aspect) {
                if (this.dangersSection.chosenAspects.length)
                    return this.matters[0].amount > 0 || this.matters[1].amount > 0 || this.matters[2].amount > 0;
            } else return this.matters[0].amount > 0 || this.matters[1].amount > 0 || this.matters[2].amount > 0;
    }

    public sample: Sample;
    public sampleForm: FormGroup;
    budgetsFilteredOptions: Observable<BudgetItem[]>;
    selectedBudget: BudgetItem;
    selectedStatus: Status;

    budgets: BudgetItem[] = [];
    categories: Category[] = [];
    emailsListOptions: string[] = [];
    dangersSection: DangersSection;
    analyzesSection: AnalyzesSection;
    emails: string[];
    private gas=[];
    private liquid=[];
    private solid=[];
    gDynamicArr=[];
    lDynamicArr=[];
    sDynamicArr=[];
    example_description= '';

    minDate: Date = new Date();

    public matters: MatterInput[] = [
        {
            type: matterType.gas,
            activate: false,
            amount: 0
        },
        {
            type: matterType.liquid,
            activate: false,
            amount: 0
        },
        {
            type: matterType.solid,
            activate: false,
            amount: 0
        }
    ]

    constructor(
        private iclLabsService: IclLabsService,
        private activatedRoute: ActivatedRoute,
        private sessionService: SessionService,
        private permissionService: PermissionsService,
        public dialog: MatDialog,
        private mailListservice: MailListService,
        private router: Router,private formBuilder: FormBuilder) { }

    ngOnInit() {
      
        this.setMailList();
        this.gDynamicForm = this.formBuilder.group({
          
        });
        this.lDynamicForm = this.formBuilder.group({
          
        });
        this.sDynamicForm = this.formBuilder.group({
          
        });
        this.mainFlow();
        this.budgetsFilteredOptions = this.sampleForm.get('budgetItemNumber').valueChanges
            .pipe(
                startWith(''),
                map(val => this.budgetFilter_(val))
            );
            if(this.iclLabsService.isLabManager(this.user)||this.iclLabsService.isLabTec(this.user)){
                this.canSeeDownload=true
            }
            
          
    }

    canDeactivate() {
        if (!this.saved)
            return confirm('ייתכן שהשינויים שביצעת לא יישמרו.');
        return true;
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnload($event) {
        return $event.returnValue = 'ייתכן שהשינויים שביצעת לא יישמרו.';
    }

    ngOnDestroy() {
        this.subscriber.unsubscribe();
    }



    budgetFilter_(val: string): BudgetItem[] {
        if (val && typeof val === 'number')
            return this.budgets.filter(budget =>
                (budget.number + '').toLowerCase().indexOf(val + ''.toLowerCase()) === 0);
        else if (!val)
            return this.budgets
    }

    getCtegoryTitle(category: Category) {
        if (this.user) {
            if (this.iclLabsService.isAdmin(this.user) ||this.iclLabsService.isLabTec(this.user)|| this.iclLabsService.canViewSampleEditAsLab(this.user, this.sample)) {
                return category.name + ' - ' + category.id;
            }
        }
        return category.name;
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if (this.budgets.filter(b => b.number === event.option.value).length > 0) {

            this.sampleForm.patchValue({
                budgetItemNumber: event.option.value,
            })
        } else {

            this.sampleForm.patchValue({
                budgetItemNumber: undefined
            })
        }
    }

    async mainFlow() {
        this.subscriber.add(this.sessionService.user.subscribe(async (user: User) => {
            if (user) {
                this.user = user;
                await this.setRequiredData();
                await this.getSample();
                if(this.sample.excel){
                    console.log(this.sample.excel)
                    this.reasultArray = this.sample.excel.split(',').filter(value => value.trim() !== ''); // Filter out empty values
                    console.log(this.reasultArray);
                    this.sample.tempExcel=this.sample.excel
                }
                this.sampleForm.get('budgetItemNumber').valueChanges.subscribe((id: number) => {
                    this.selectedBudget = this.getBudgetByNumber(id);
                });
                if (this.sample.status >= STATUS_CONST.STATUS_CREATED && this.iclLabsService.canViewSampleEditAsLab(this.user, this.sample)) {
                    this.sampleForm.get('expectResultsDate').setValidators([Validators.required]);
                }
                // if (this.sampleForm.get('expectResultsDate').value) {
                //     this.minDate = new Date(this.sampleForm.get('expectResultsDate').value);
                // }
            }
        }))
       
    }
    deleteExecl(item){
        let modifiedString =  this.sample.excel.replace(item + ',', ''); // Removing '222' and the trailing comma
console.log(modifiedString); // Output: '111,333'
this.sample.tempExcel=modifiedString;
this.updateSample()

        
    }
    private async setMailList() {
        this.saved_Mails = [];
        this.lists_name=[];
       this.saved_mails = await this.mailListservice.getsavedLists();
        if (this.saved_mails) {
            this.saved_mails.map((saved_mails: MailList) => {
                    if(saved_mails.list_name)
                    this.lists_name.push(saved_mails.list_name);
            })
          
        }
       
       
    }
    joinMailsLists(){
        const temp_arry=[];
        for(let index=0;index< this.saved_Mails.length;index++){
            this.saved_mails.map((saved_mails: MailList) => {
                if(saved_mails.list_name==this.saved_Mails[index])
                temp_arry.push(saved_mails.emailsString);
                
               
        })
    
        }
        temp_arry.push(this.emails);
        // temp_arry.push(this.user.email)
       this.selected_lists=temp_arry.join();
       
        
    }

    getBudgetById(id: number): BudgetItem {
        return this.budgets.filter(budget => budget.id === id)[0];
    }

    getBudgetByNumber(number: number): BudgetItem {
        return this.budgets.filter(budget => budget.number === number)[0];
    }

    canDelete(sample: Sample) {
        return sample?.status === 0 && sample?.statusLog[sample?.statusLog?.length - 1]?.userId === this.user?.id;
    }

    async deleteSample(sample: Sample) {
        if (confirm('נא אשרו מחיקה')) {
            const res = await this.iclLabsService.deleteSample(sample);
            if (res && res.success) {
                this.isDeletedModal = true;
                this.saved = true;
                setTimeout(() => {
                    this.router.navigateByUrl('/samples')
                }, 2000);
            } else {
                this.uploadFileError = "מחיקה נכשלה";
            }
        }
    }

    canDownloadFile() {
        if (this.sample && this.user && this.sample.excel) {
            if (this.sample.userId === this.user.id) {

                if (this.permissionService.canViewAdminArea(this.user)) {
                    return true;
                }

                if (this.sample.status === 7 && this.sample.excel) {
                    return true;
                } else {
                    return false;
                }


            } else {
                if (this.permissionService.canViewAdminArea(this.user) || this.permissionService.canViewWorkerLabArea(this.user)) {
                    return true;
                }
                else {
                    return false
                }
            }
        } else {
            return false;
        }
    }

    async updateSample(sample?) {
        if(sample){
           let extrnal_sample = await this.iclLabsService.getSample(sample.id);    
           extrnal_sample.status=7
            this.iclLabsService.putSample(extrnal_sample, sample.id).then(data => {
                this.dialog.open(DialogElementsExampleDialog);
                this.saved = true;
                setTimeout(() => {
                    this.dialog.closeAll();
                window.location.reload()
                }, 2000);
            });
            await this.getSample();
            }
            else{
                const sampleToUpdate: Sample = this.getSampleReadyForUpdate();
                if (this.sample) {
        
                    if (sampleToUpdate?.status !== 5 /* && sampleToUpdate?.reasonReject */) {
                        sampleToUpdate.reasonReject = null;
                    }
        
                    if (!sampleToUpdate?.excel && sampleToUpdate?.status === 6) {
                        this.uploadFileError = 'לא ניתן להעביר לסטטוס ממתין לאישור ללא העלאת קובץ';
                        return false;
                    }
                    this.iclLabsService.putSample(sampleToUpdate, this.sample.id).then(data => {
                        this.dialog.open(DialogElementsExampleDialog);
                        this.saved = true;
                        setTimeout(() => {
                            this.dialog.closeAll();
                        window.location.reload()
                        }, 2000);
                    });
                    await this.getSample();
                }
            }
      

    }

    getSampleReadyForUpdate() {
     
        this.gas=this.sessionService.get_gas();
        this.liquid=this.sessionService.get_liquid();
        this.solid=this.sessionService.get_solid();
        for(let i=0;i<this.gas.length;i++){
            this.example_description=this.example_description+','+this.gas[i]
        }
        for(let i=0;i<this.liquid.length;i++){
            this.example_description=this.example_description+','+this.liquid[i]
        }
        for(let i=0;i<this.solid.length;i++){
            this.example_description=this.example_description+','+this.solid[i]
        }
        this.joinMailsLists();
        if (this.user && this.sample) {
            const sample = new Sample({
                project: this.sampleForm.controls['project'].value,
                subject: this.sampleForm.controls['subject'].value,
                samlingPoint: this.sampleForm.controls['samlingPoint'].value,
                suspention: this.matters[matterType.gas].activate ? this.matters[matterType.gas].amount : 0,
                solid: this.matters[matterType.solid].activate ? this.matters[matterType.solid].amount : 0,
                solution: this.matters[matterType.liquid].activate ? this.matters[matterType.liquid].amount : 0,
                analyzesCustom: this.analyzesSection.customAnalyzes ? this.analyzesSection.customAnalyzes.join() : "",
                analyzes: this.analyzesSection.chosenAnalyzes,
                cordinationLaboratory: this.dangersSection.cordinationLaboratory,
                aspect: this.dangersSection.aspect,
                aspects: this.dangersSection.chosenAspects ? this.dangersSection.chosenAspects : [],
                aspectsCustom: this.dangersSection.chosenAspects ? this.dangersSection.customAspects.join() : "",
                emailsString: this.selected_lists,
                status: this.selectedStatus.id,
                categoryId: this.sampleForm.controls['categoryId'].value,
                budgetItemId: this.getBudgetByNumber(this.sampleForm.controls['budgetItemNumber'].value).id,
                expectResultsDate: new Date(this.sampleForm.controls['expectResultsDate'].value).toISOString(),
                userId: this.user.id,
                reasonReject: this.selectedStatus.reasonReject ? this.selectedStatus.reasonReject : "",
                excel: this.sample.tempExcel ? this.sample.tempExcel:'',
                numOfOrders: this.countMatters(),
                gas_exampels: this.gas,
                liquid_exampels: this.liquid,
                solid_exampels: this.solid,
                example_description: this.example_description,
                comments:  this.sampleForm.controls['comments'].value,
                preference: this.sampleForm.controls['preference'].value,
                work_hours: this.analyzesSection.work_hours,
                

            })
            return sample;
        }
        return null
    }

    countMatters(): number {
        const gas = this.matters[matterType.gas].activate ? this.matters[matterType.gas].amount : 0;
        const solid = this.matters[matterType.solid].activate ? this.matters[matterType.solid].amount : 0
        const liquid = this.matters[matterType.liquid].activate ? this.matters[matterType.liquid].amount : 0
        
        this.cunclsion=Number(gas) + Number(solid) + Number(liquid)
        return this.cunclsion;
    }

    async getSample() {
        let id = this.activatedRoute.snapshot.paramMap.get('id');
        if (id) {
            this.sample = await this.iclLabsService.getSample(parseInt(id));
            this.work_temp=this.sample.work_hours;
            if (this.sample) {
                this.updateFormGroup();
                this.updateMatters();
                this.updateAnalyzes();
                this.updateAspects();
                this.updateEmails();
                this.updateStatus();
                this.updateExampels();
               
            }
        }
    }

    updateFormGroup() {
        this.sampleForm.patchValue({
            project: this.sample.project,
            subject: this.sample.subject,
            samlingPoint: this.sample.samlingPoint,
            budgetItemNumber: this.sample.BudgetItem ? this.sample.BudgetItem.number : '',
            categoryId: this.sample.categoryId,
            comments: this.sample.comments,
            preference: this.sample.preference,
            expectResultsDate: this.sample.expectResultsDate ? this.sample.expectResultsDate : new Date(),
            gas_exampels: this.sample.gas_exampels,
            liquid_exampels: this.sample.liquid_exampels,
            solid_exampels: this.sample.solid_exampels,

        })

        if (!this.canEdit()) {
            this.sampleForm.disable();
        }
        //ALWAYS DISABLE THIS INPUT
        this.sampleForm.get('expectResultsDate').disable();
        //UNLESS THIS USER IS FROM LAB
        if (this.iclLabsService.canViewSampleEditAsLab(this.user, this.sample)) {
            this.sampleForm.get('expectResultsDate').enable();
        }

        //UPDATE SELECTED BUDGET
        this.selectedBudget = this.sample.BudgetItem;

        //IF IS NOT LAB AND STATUS IS GREATER THEN 0 THEN DEISABLE SELECTED BUGET
        if (!this.iclLabsService.canViewSampleEditAsLab(this.user, this.sample) && this.sample.status > 0) {
            this.sampleForm.get('budgetItemNumber').disable();
        } else {
            this.sampleForm.get('budgetItemNumber').setValidators(this.budgetItemValidator.bind(this));
        }


    }

    async setEmailOptions(): Promise<void> {
        const users = await this.iclLabsService.getUsers();
        if (users)
            users.map((user: User) => {
                if (user.email)
                    this.emailsListOptions.push(user.email);
            })
    }

    updateMatters() {
        this.matters[0].activate = this.sample.suspention > 0;
        this.matters[0].amount = this.sample.suspention;

        this.matters[1].activate = this.sample.solution > 0;
        this.matters[1].amount = this.sample.solution;

        this.matters[2].activate = this.sample.solid > 0;
        this.matters[2].amount = this.sample.solid;
    }
    updateExampels(){
        const gas=Object.keys(this.sample.gas_exampels)
        const gas_length = gas.length;
        for (let i = 0; i < gas_length; i++) {
            this.controlName='newG'+i
            this.gDynamicForm.addControl(this.controlName, new FormControl(this.sample.gas_exampels[i]));
            console.log(this.sample.gas_exampels[i]);
            
          }
          const liquid=Object.keys(this.sample.liquid_exampels)
          const liquid_length = liquid.length;
          for (let i = 0; i < liquid_length; i++) {
              this.controlName='newL'+i
              this.lDynamicForm.addControl(this.controlName, new FormControl(this.sample.liquid_exampels[i]));
            }
            const solid=Object.keys(this.sample.solid_exampels)
            const solid_length = solid.length;
            for (let i = 0; i < solid_length; i++) {
                this.controlName='newS'+i
                this.sDynamicForm.addControl(this.controlName, new FormControl(this.sample.solid_exampels[i]));
              }
              this.flag=true
      
    }

    updateAnalyzes() {
        this.analyzesSection.chosenAnalyzes = this.sample.Analyses ? this.sample.Analyses.map(analyse => analyse.id) : [];
        this.analyzesSection.customAnalyzes = this.sample.analyzesCustom ? this.sample.analyzesCustom.split(",") : [];
    }

    updateAspects() {
        this.dangersSection.cordinationLaboratory = this.sample.cordinationLaboratory;
        this.dangersSection.aspect = this.sample.aspect;

        this.dangersSection.chosenAspects = this.sample.Aspects ? this.sample.Aspects.map(aspect => aspect.id) : [];
        this.dangersSection.customAspects = this.sample.aspectsCustom ? this.sample.aspectsCustom.split(',') : [];
    }

    updateEmails() {
        this.emails = this.sample.emailsString.split(',');
    }

    updateStatus() {
        this.filterStatuses();
        this.selectedStatus = this._statuses.filter(status => status.id === this.sample.status)[0];
        if (this.sample.reasonReject) {
            this.selectedStatus.reasonReject = this.sample.reasonReject;
        }
    }

    setFormGroup() {
        this.sampleForm = new FormGroup({
            project: new FormControl({ value: '' }, [Validators.required]),
            subject: new FormControl({ value: '' }, [Validators.required]),
            samlingPoint: new FormControl({ value: '' }),
            budgetItemNumber: new FormControl({ value: '' }, ),
            categoryId: new FormControl({ value: '' }, [Validators.required]),
            expectResultsDate: new FormControl({ value: "" }),
            comments: new FormControl({ value: '' }, []),
            preference: new FormControl({ value: '' }, [Validators.required]),

        });
    }

    async setBudgets(user: User) {
        let result = [];
        // if (user && !this.iclLabsService.isAdmin(user) && user.factoryId) {
        //     result = await this.iclLabsService.getBudgets({ factoryId: user.factoryId });
        // } 
        // else {
            result = await this.iclLabsService.getBudgets();
        // }
        this.budgets = result;
    }

    budgetItemValidator(control: AbstractControl): { [key: string]: boolean } | null {
        if (this.budgets)
            if (control.value !== undefined && this.budgets.filter(b => b.number === control.value).length === 0) {
                return { 'ageRange': true };
            }
        return null;
    }

    async setCategories() {
        const result = await this.iclLabsService.getCategories();
        this.categories = result;
    }

    async setAspects() {
        const result: Aspect[] = await this.iclLabsService.getAspects({ factoryId: 1 });
        this.dangersSection = {
            cordinationLaboratory: false,
            aspect: false,
            aspects: result,
            chosenAspects: [],
            customAspects: [],
          
        }
    }

    async setAnalyses() {
      
        const result: Analyze[] = await this.iclLabsService.getAnalyzes();
        this.analyzesSection = {
            analyzes: result,
            chosenAnalyzes: [],
            customAnalyzes: [],
            work_hours:this.work_temp,
        }
    }

    async setRequiredData() {
        this.setFormGroup();
        await this.setCategories();
        await this.setBudgets(this.user);
        await this.setAspects();
        await this.setAnalyses();
        await this.setEmailOptions();
    }

    isFormDisabled() {
        //IN ADMIN CASE THAT CAN NOT EDIT
        if (this.user && (this.iclLabsService.isAdmin(this.user) && !this.canEdit())||(this.iclLabsService.isLabTec(this.user) && !this.canEdit()))
            return false
        //CUSTOMER CASE
        return (!this.mattersValid) || (!this.analyzesValid)
    }

    canEdit(input?: string): boolean {

        if (this.user && this.sample) {
            //SPECIAL USER
            if (input) {
                if (input === 'emails' || input === 'results' || input === 'status')
                    return this.iclLabsService.canEdit(this.user, this.sample, input);
            }
            else {
                //DEPENDS ON STATUS
                if (this.sample.status >= STATUS_CONST.STATUS_IN_LAB){  
                    if(this.sample.status==STATUS_CONST.STATUS_CANCELED){
                        return  this.iclLabsService.canEdit(this.user, this.sample);
                    }
                    if(this.iclLabsService.isLabTec(this.user)){
                        this.sampleForm.controls['comments'].enable()
                    }
                   
                    if(this.sample.status === STATUS_CONST.STATUS_WORK&&this.iclLabsService.isLabTec(this.user)||this.sample.status === STATUS_CONST.STATUS_WORK&&this.iclLabsService.isLabManager(this.user)) {
                        this.sampleForm.controls['project'].disable()
                     this.sampleForm.controls['subject'].disable()
              this.sampleForm.controls['samlingPoint'].disable()
              this.sampleForm.controls['categoryId'].disable()
              this.sampleForm.controls['budgetItemNumber'].disable()
                        this.sampleForm.controls['comments'].enable()
                        
                        
                        // this.sampleForm.get('solid_exampels').enable();
                        return  this.iclLabsService.canEdit(this.user, this.sample);
                    }
                    else{
                        return false
                    }      
                   
            }
          
                else
                {
                    return  this.iclLabsService.canEdit(this.user, this.sample);

                }
       
                   
            }

    
              
        }
    }
    verify_preference(){
        if((this.sample.status >= STATUS_CONST.STATUS_HANDED_TO_LAB)&&(this.user.Permission.labManager==true))
            return true
    }

    canEditStatus() {
        let ans: boolean = false;
    
    
        if (this.sample && this.user) {
            //THIS IS CREATOR 
            if (this.canEdit()) {
                if (this.sample.status === STATUS_CONST.STATUS_CREATED || this.sample.status === STATUS_CONST.STATUS_HANDED_TO_LAB||this.sample.status === STATUS_CONST.STATUS_WORK||this.sample.status === STATUS_CONST.STATUS_CANCELED) {
                    ans = true;
                }
                //THIS IS LABORATOR
            } else if (this.canEdit('status') && this.sample.status >= 1) {
                //SAMPLE IS NOT DONE
                if (this.sample.status !== STATUS_CONST.STATUS_DONE)
                    ans = true;
                //SAMPLE IS DONE
                else {
                    //ONLY IF THIS IS LAB MANAGER THAN CAN EDIT STATUS
                    if (this.iclLabsService.isLabManager(this.user))
                        ans = true;
                }
            }
        }
        return ans;
    }

    filterStatuses() {
        if (this.sample && this.user) {
            //CREATOR AND LAB
            if (this.canEdit() && this.canEdit('status')) {
                if ((this.iclLabsService.isAdmin(this.user))) {
                    this._statuses = this._statuses;
                } 
                else if(this.iclLabsService.isLabManager(this.user)){
                    this._statuses = this._statuses;
                }
                else if((this.sample.status >=STATUS_CONST.STATUS_HANDED_TO_LAB)&&(this.iclLabsService.isCustomer(this.user)&&this.iclLabsService.isLabTec(this.user))||this.iclLabsService.isLabTec(this.user)){
                    this._statuses = this._statuses.filter(status => status.id >= 1 && status.id < this._statuses.length - 2);
                }
                else {
                    if (this.sample.status !== STATUS_CONST.STATUS_DONE) {

                        this._statuses = this._statuses.filter(status => status.id < this._statuses.length - 1);
                    }
                }
            }
            //CREATOR
            else if ((this.canEdit()|| this.canEdit('status'))&&this.iclLabsService.isCustomer(this.user)&&!this.iclLabsService.isLabTec(this.user)&&!this.iclLabsService.isLabManager(this.user)&&!this.iclLabsService.isAdmin(this.user)) {
                if (this.sample.status === STATUS_CONST.STATUS_CREATED || this.sample.status === STATUS_CONST.STATUS_HANDED_TO_LAB|| this.sample.status === STATUS_CONST.STATUS_CANCELED) {
                    this._statuses = this._statuses.filter(status => status.id <2||status.id==8);
                }
                //LAB
            } 
            else if (this.canEdit('status')) {
                if (this.iclLabsService.isLabManager(this.user)) {
                    if (this.sample.status !== STATUS_CONST.STATUS_CREATED) {
                        this._statuses = this._statuses.filter(status => status.id >= 1);
                    }
                } else {
                    if (this.sample.status !== STATUS_CONST.STATUS_CREATED && this.sample.status !== STATUS_CONST.STATUS_DONE) {
                        this._statuses = this._statuses.filter(status => status.id >= 1 && status.id < this._statuses.length - 2);
                    }
                }
            }
        }
    }
    canEditReject() {
        if (this.user) {
            if (this.user.Permission && (this.user.Permission.labManager || this.user.Permission.labTechnician)) {
                return true;
            }
        }
        return false;
    }
// check with rahel
    canUploadResults(): boolean {
        let ans: boolean = false;
        if (this.user && this.sample) {
            if (this.sample.status >= STATUS_CONST.STATUS_WORK && this.canEdit('results')) {
              
                ans = true;
                if(this.sample.status === STATUS_CONST.STATUS_DONE&&(this.user.Permission.admin||this.user.Permission.labManager)){
                    ans = true; 
                }
                else{
                    ans=false
                }
            }
        }
      

        return ans;
    }
    caDownloadExcel() {
        // to do
    }
    canUploadFile() {
        if (!this.sample.tempExcel && this.sample.status === 6) {
            return false;
        } else {
            return true;
        }
    }
    async handleFile(e: any) {
        let files
        if (this.sample) {
            this.updateBtnNotActive = true;
            files = await this.iclLabsService.handleFile(e, this.sample);
            if (files && files.length>0) {
                console.log('uploaded');
                this.uploadedFile=true
                
                if(this.sample.tempExcel&&this.sample.excel!=""){
                   this.fileArray = this.sample.tempExcel.split(','); 
                       // Remove empty strings that may result from trailing commas
                const filteredFileArray = this.fileArray.filter(file => file.trim() !== '');
                const files2 = files.split(',');
                // Remove empty strings that may result from trailing commas
                const filteredFiles2 = files2.filter(file => file.trim() !== '');
                filteredFiles2.forEach(element1 => {
                    let duplicate=false
                    for (let element2 of filteredFileArray) {
                        if (element1 === element2) {
                          duplicate = true;
                          break;  // Exit the inner loop and continue with the outer loop
                        }
                      }
                    if(duplicate===false)
                            this.sample.tempExcel = this.sample.tempExcel+element1+',';
                
                });
               
                }
                else{
                    if(this.sample.excel===""){
                        this.sample.tempExcel = this.sample.excel+files;
                     }
                }
             
            
                this.uploadFileError = "";
            }
            this.updateBtnNotActive = false;
        }
    }

    async handleDownload(e: any) {
        if (this.sample)
            await this.iclLabsService.handleDownload(e, this.sample);
    }

    async handlerResults(e: any) {
        if (this.sample.excel)
          alert(this.sample.excel)
    }


}

@Component({
    selector: 'success-dialog',
    templateUrl: 'success-dialog.html',
})
export class DialogElementsExampleDialog {
}