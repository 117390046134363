import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnDestroy, OnInit } from "@angular/core";
import { Subscriber } from 'rxjs';
import { LoaderService } from '../../services/loader.service';

@Component({
    selector: 'global-loader',
    styleUrls: ['./loader.component.scss'],
    templateUrl: './loader.component.html',
    animations: [
        trigger(
            'fadeInOut', [
            transition(
                ':enter',
                [
                    style({ opacity: 0 }),
                    animate('0.2s ease-out',
                        style({ opacity: 1 }))
                ]
            ),
            transition(
                ':leave',
                [
                    style({ opacity: 1 }),
                    animate('0.2s ease-in',
                        style({ opacity: 0 }))
                ]
            )]
        )]
})

export class LoaderComponent implements OnInit, OnDestroy {
    isOpen: boolean = false;
    private subscriber = new Subscriber();

    constructor(private loaderService: LoaderService) { }

    ngOnInit() {
        this.subscriber.add(this.loaderService.status$.subscribe((status: boolean) => {
            //LINE 42 WILL LET YOU DISABLE THE LOADER WITH THE LOCALSTORAGE ATTR GLOBALLOADERSHUTDOWN
            let globalLoaderShutDown: boolean = localStorage.getItem('globalLoaderShutdown') && localStorage.getItem('globalLoaderShutdown') === 'true' ? true : false;
            this.isOpen = status && !globalLoaderShutDown;
        }))
    }
    ngOnDestroy() {
        this.subscriber.unsubscribe()
    }

}

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        LoaderComponent
    ],
    declarations: [
        LoaderComponent
    ]
})

export class LoaderModule { }