  <h1 mat-dialog-title>Files</h1>
      <div mat-dialog-content>
        <ul>
          <div *ngFor="let file of files" (click)="openFile(file)" style="cursor:pointer;">
            {{ file }}
            <img  src="assets/images/download.png" alt="" width="100">
            </div>
        </ul>
      </div>
      <div mat-dialog-actions>
        <button mat-button (click)="closeModal()">Close</button>
      </div>