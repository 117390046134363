import { Injectable } from "@angular/core";
import { BudgetItem } from '../base/BudgetItem';
import { IclLabsAPIService } from './icl-labs.api.service';
import { SessionService } from "./session.service";

@Injectable({
    providedIn: "root"
})

export class BudgetService {
    constructor(private iclLabsApiService: IclLabsAPIService, public sessionService : SessionService) { }

    async getBudgets(params?: any): Promise<BudgetItem[]> {
        try {
            let budgetItems: BudgetItem[] = [];
            const result = await this.iclLabsApiService.get('/budgetItem', { params });
            if (result && result.budgetItems) {
                result.budgetItems.map(budgetItem => budgetItems.push(new BudgetItem(budgetItem)));
            }
            return budgetItems;
        } catch (err) {
            console.error(err);
        }
    }

    async createBudget(budget: Partial<BudgetItem>): Promise<BudgetItem> {
        try {
            const result = await this.iclLabsApiService.post('/budgetItem', { body: budget });
            if (result && result.budgetItem) {
                return new BudgetItem(result.budgetItem)
            } else {
                throw new Error('something went wrong')
            }
        }
        catch (err) {
            throw new Error(err);
        }
    }

    async editBudget(id: number, budget: Partial<BudgetItem>): Promise<boolean> {
        try {
            const result = await this.iclLabsApiService.put('/budgetItem/' + id, { body: budget });
            if (result && result.success) {
                return result.success;
            }
            return false;
        } catch (err) {
            console.error(err);
        }
    }

    async deleteBudget(id: number, params?: any): Promise<boolean> {
        try {
            const result = await this.iclLabsApiService.delete('/budgetItem/' + id, { params });
            if (result && result.success) {
                return result.success;
            }
            return false;
        } catch (err) {
            console.error(err);
        }
    }
}