import { Injectable } from '@angular/core';
import { Category } from '../base/Category';
import { IclLabsAPIService } from './icl-labs.api.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private iclLabsApiService: IclLabsAPIService, private sessionService : SessionService ) {}

  async getCategories(): Promise<Category[]> {
    try {
      const params = {labId : this.sessionService.getLabId()};
      let categories: Category[] = [];
      const result = await this.iclLabsApiService.get('/category',{ params });
      if (result && result.categories) {
        result.categories.map((category) =>
          categories.push(new Category(category))
        );
      }
      return categories;
    } catch (err) {
      console.error(err);
    }
  }
}
