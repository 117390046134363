<div class="icl-logo-background"></div>
<form *ngIf="user  && sample" [formGroup]="sampleForm">
  <div class="section bg-area pt-5">
    <div
      class="p-4 w-75 mr-auto ml-auto mb-4 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center bg-status m-0">
      <div class="size-normal d-flex align-items-center">
        <span>מספר הזמנה:</span>
        <span class="font-weight-bold p-2">
          {{ sample?.id }}
        </span>
      </div>
      <div class="size-normal status-area d-flex align-items-baseline">
        <span class="mx-2">סטאטוס:</span>
        <!-- <span class="font-weight-bold p-2">
                    <div class="value bg-{{sample?.status}} p-0">
                        {{sample?.status ? _statuses[sample?.status].title : 'נוצר'}}
                    </div>
                </span> -->
        <global-status-select *ngIf="sample?.id" [disabled]="!canEditStatus()" [disableReject]="!canEditReject()"
          [statuses]="_statuses" [(selectedStatus)]="selectedStatus">
        </global-status-select>
      </div>

      <div class="download d-flex align-items-center mt-2 mt-md-0 justify-content-between">
        <label *ngIf="canUploadResults()" for="file_excel" class="m-0">
          <div
            class="mat-focus-indicator icl-btn dark font-weight-bold size-small-normal ml-2 mr-2 mat-raised-button mat-button-base">
            <img src="assets/images/upload.svg" />
            <span>העלה תוצאות</span>
          </div>
        </label>
        <button display="false" *ngIf="canDownloadFile()&&((sample.status==7&&this.iclLabsService.isCustomer(this.user))||(this.canSeeDownload))" (click)="handleDownload($event)"
          class="icl-btn ghosted font-weight-bold size-small-normal" mat-raised-button>
          <img src="assets/images/download.svg" />
          <span>הורד תוצאות</span>
        </button>
      <div style="left: -15px;
      position: relative;">
        <div *ngFor="let item of this.reasultArray; let i = index">
          {{ item }}
          <mat-icon style="  cursor: pointer;" *ngIf="canUploadResults()" (click)="deleteExecl(item)" >delete</mat-icon>
          <br></div>
      </div>
        <input [disabled]="!canUploadResults()" id="file_excel" type="file" name="files[]" multiple
          style="position: absolute; visibility: hidden; opacity: 0" (change)="handleFile($event)" />
      </div>
      <div class="d-flex justify-content-center" *ngIf="uploadedFile==true">
        <span>*הקובץ הועלה ויעודכן לאחר שמירה*</span>
      </div>
    </div>

    <div class="container">
      <div class="row pb-5 m-0">
        <div class="col-11 d-flex flex-wrap align-items-center">
          <div class="input-wrapper">
            <mat-form-field>
              <mat-label>שם הפרויקט</mat-label>
              <input required formControlName="project" matInput placeholder="" value="" maxLength="30" />
            </mat-form-field>
          </div>
          <div class="input-wrapper">
            <mat-form-field>
              <mat-label>נושא</mat-label>
              <input required formControlName="subject" matInput placeholder="" value="" maxLength="30" />
            </mat-form-field>
          </div>
          <div class="input-wrapper">
            <mat-form-field>
              <mat-label>נק. דיגום/שלב בתהליך </mat-label>
              <input  formControlName="samlingPoint" matInput placeholder="" value="" maxLength="20" />
            </mat-form-field>
          </div>
          <div *ngIf="this.verify_preference()" class="input-wrapper">
            <mat-form-field>
                <mat-label> עדיפות</mat-label>
                <mat-select required formControlName="preference">
                    <mat-option *ngFor="let preference of preferences" [value]="preference.name">
                        {{ preference.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
          <div class="input-wrapper">
            <mat-form-field>
              <mat-label> קטגוריה</mat-label>
              <mat-select required formControlName="categoryId">
                <mat-option *ngFor="let category of categories" [value]="category.id">
                  {{ getCtegoryTitle(category) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="input-wrapper">
            <mat-form-field>
              <!--<mat-label>סעיף תקציבי</mat-label>
             <mat-select required formControlName="budgetItemId">
                <mat-option *ngFor="let budget of budgets" [value]="budget.id">
                  {{ budget.name }} - {{ budget.description }}
                </mat-option>
              </mat-select>-->
              <input type="text" placeholder="סעיף תקציבי*" matInput formControlName="budgetItemNumber"
                [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let budget of budgetsFilteredOptions | async" [value]="budget.number">
                  {{ budget.name }} - {{ budget.number }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="input-wrapper">
            <span class="size-small font-weight-bold">{{
              selectedBudget?.name
              }}</span>
          </div>
          <div class="input-wrapper">
            <!-- <mat-form-field>
              <mat-label>בחר צפי להעברת תוצאות</mat-label>
              <input readonly formControlName="expectResultsDate" [min]="minDate" matInput [matDatepicker]="picker" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="container">
      <div class="row pt-5 pb-5 m-0">
        <div class="col-12 m-0 pb-4 p-md-0 pb-md-4">
          <span [ngClass]="{ 'color-red': !mattersValid }" class="font-weight-bold size-small-normal">
            תיאור דוגמה*
          </span>
        </div>
        <div class="col-4">
          <global-matter [disabled]="(!canEdit())||this.iclLabsService.isLabTec(this.user)&&this.sessionService.getLabId()==2" [(matters)]="matters"></global-matter>
        </div>
      </div>
    </div>
  </div>

  <div class="section bg-area after-white">
    <div class="container">
      <div class="row pt-5 pb-5 m-0">
        <div class="col-12 m-0 pb-4 p-md-0 pb-md-4">
          <span [ngClass]="{ 'color-red': !analyzesValid }" class="font-weight-bold size-small-normal">
            אנליזות*
          </span>
        </div>
        <div class="col-11 col-md-10">
          <icl-labs-analyzes *ngIf="sample?.id" [disabled]="!canEdit()||this.iclLabsService.isLabTec(this.user)&&this.sessionService.getLabId()==2" [(analyzesSection)]="analyzesSection">
          </icl-labs-analyzes>
        </div>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="container">
      <div class="row pt-5 pb-5 m-0">
        <div class="col-12 m-0 pb-4 p-md-0 pb-md-4">
          <span class="font-weight-bold size-small-normal">
            סיכונים בטיחותיים וסביבתיים
          </span>
        </div>
        <div class="col-12 col-md-6">
          <icl-labs-dangers *ngIf="sample?.id" [(dangersSection)]="dangersSection" [disabled]="!canEdit()">
          </icl-labs-dangers>
        </div>
      </div>
    </div>
  </div>

  <div class="section bg-area">
    <div class="container">
      <div class="row pt-5 pb-5 m-0">
        <div class="col-12 col-md-3 m-0 p-md-0">
          <span class="font-weight-bold size-small-normal">
            כתובות מייל לקבלת הדו"ח
          </span>
        </div>
        <div class="col-12 col-md-6 m-0 p-0">
          <global-chip-input *ngIf="sample?.id && emailsListOptions" [options]="emailsListOptions"
           [(itemList)]="emails" [customPlaceholder]="'הכנס אימייל ולחץ ENTER'">
          </global-chip-input>
        </div>
      </div>
    </div>
  </div>
  <div class="section bg-area">
    <div class="container">
        <div class="row pt-5 pb-5 m-0">
            <div class="col-12 col-md-3 m-0 p-md-0">
                <span class="font-weight-bold size-small-normal">
                 רשימות תפוצה לקבלת הדו"ח
                </span>
            </div>
            <div class="col-12 col-md-6 m-0 p-0">
                <global-chip-input *ngIf="this.saved_Mails" [options]="this.lists_name" [(itemList)]=" this.saved_Mails"
                    [customPlaceholder]="'הכנס אימייל ולחץ ENTER'"></global-chip-input>
                    
            </div>
        </div>
    </div>
</div>
  <div class="section">
    <div class="container">
      <div class="row pt-5 pb-5 m-0">
        <div class="col-12 col-md-3 m-0 p-md-0">
          <span class="font-weight-bold size-small-normal">
            הערות
          </span>
        </div>
        <div class="col-12 col-md-6 m-0 p-0">
          <mat-form-field>
            <mat-label>הערות</mat-label>
            <textarea  formControlName="comments" matInput placeholder="" value="" style="width: 500px;height: 100px; "></textarea>
        </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="container">
      <div class="row pt-5 pb-5 m-0">
        <div class="section">
          <div class="container">
            <div class="row pb-5 m-0">
              <div class="col-12 d-flex justify-content-end">
                <div *ngIf="uploadFileError !== ''" style="color: red; width: 600px; height: 20px">
                  {{ uploadFileError }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-end">
          <!-- <button *ngIf="canDelete(sample)" (click)="deleteSample(sample)"
            class="mx-1 icl-btn danger font-weight-bold size-small-normal" mat-raised-button>
            <span>מחק הזמנה</span>
          </button> -->
          <button [disabled]="(isFormDisabled() && canUploadFile())  || updateBtnNotActive" (click)="updateSample()"
            class="mx-1 icl-btn dark font-weight-bold size-small-normal" mat-raised-button>
            <span>עדכן הזמנה</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</form>

<global-modal [(isOpen)]="isDeletedModal">
  <div class="row p-0 align-items-center justify-content-start">
    <div class="col-12">
      <h4 class="size-small-normal font-weight-bold">ההזמנה נמחקה בהצלחה!</h4>
    </div>
  </div>
</global-modal>