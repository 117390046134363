import { Aspect } from './Aspect';
import { BudgetItem } from './BudgetItem';
import { Entity } from './Entity'
import { STATUS_CONST } from './Status';
import { User } from './User';

export class Sample extends Entity {

    Analyses: Analyses[];
    BudgetItem: BudgetItem;
    Factory: Factory;
    Users: User[];
    Category: Category;
    Aspects: Aspect[];
    labId : number;
    id: number;
    number: number;
    project: string;
    example_description: string;
    comments: string;
    preference: string;
    createdAt: string;
    subject: string;
    samlingPoint: string;
    userName: string;
    suspention: number;
    solid: number;
    solution: number;
    cordinationLaboratory: any;
    aspect: any;
    status: number;
    excel: any;
    expectResultsDate: any;
    numOfOrders: number;
    gas_exampels: object| null;
    liquid_exampels: object| null;
    solid_exampels: object| null;
    work_hours: number;

    categoryId: number;
    budgetItemId: number;

    factoryId: number;
    userId: number;

    analyzesCustom: any;
    analyzes: number[];

    aspectsCustom: string;
    aspects: number[];

    emailsString: string;
    created_status:any;
    handed_to_lab_status:any;

    statusLog: StatusLog[];

    reasonReject?: string;
    tempExcel?: string;

    constructor(obj: Partial<Sample>) {
        super(obj)
        this.Users = this.Users ? this.Users.map(user => new User(user)) : [];
        if (this.statusLog && this.statusLog.length)
            this.statusLog = this.statusLog.reverse();
    }

    get status_change_time(): Date {
        return this.statusLog && this.statusLog.length ? new Date(this.statusLog[0].updatedAt) : new Date();
    }
    get status_created(): Date {
        this.created_status=this.statusLog[this.statusLog.length-1].updatedAt;
        return this.statusLog && this.statusLog.length ? new Date(this.created_status) : new Date();
    }
    get status_handed_to_lab(): Date {
        this.handed_to_lab_status=this.statusLog.filter(status=>status.status==1).map(status=>status.updatedAt)
        return this.statusLog && this.statusLog.length ? new Date(this.handed_to_lab_status) : new Date();
    }

    get changed_by(): string {
        return this.statusLog && this.statusLog.length && this.statusLog[0].User ? this.statusLog[0].User.first_name + ' ' + this.statusLog[0].User.last_name : '';
    }

    get creator_name(): string {
        return this.statusLog && this.statusLog.length && this.statusLog[this.statusLog.length - 1].User ? this.statusLog[this.statusLog.length - 1].User.first_name + ' ' + this.statusLog[this.statusLog.length - 1].User.last_name : '';
    }

    get priority(): string {
        return this.Category ? this.Category.name : '';
    }

    get amount(): number {
        return this.solid + this.solution + this.suspention;
    }

    get isDone(): boolean {
        return this.status === STATUS_CONST.STATUS_DONE;
    }

    setLabId(labId){
        this.labId = labId;
    }
}

export interface Analyses {
    id: number;
    name: string;
    description: string;
    sampleAnalyzes: sampleAnalyzes;
}

export interface sampleAnalyzes {
    createdAt: string;
    updatedAt: string,
    sampleId: number;
    analysisId: number;
}

export interface Factory {
    id: number;
    number: number;
    name: string;
    companyId: number;
}

export interface Category {
    id: number;
    order: number;
    name: string;
}

export interface StatusLog {
    User: User
    id: number
    sampleId: number
    status: number
    userId: number
    updatedAt: string
}