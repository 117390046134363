import { Injectable } from '@angular/core';
import { Analyze } from 'src/app/globals/base/Analyze';
import { Aspect } from 'src/app/globals/base/Aspect';
import { BudgetItem } from 'src/app/globals/base/BudgetItem';
import { Category } from 'src/app/globals/base/Category';
import { Sample } from 'src/app/globals/base/Sample';
import { Lab } from 'src/app/globals/base/Lab';
import { Factory, User } from 'src/app/globals/base/User';
import { AnalyzeService } from 'src/app/globals/services/analyze.service';
import { AspectService } from 'src/app/globals/services/aspect.service';
import { BudgetService } from 'src/app/globals/services/budgets.service';
import { CategoryService } from 'src/app/globals/services/category.service';
import { FactoryService } from 'src/app/globals/services/factory.service';
import { PermissionsService } from 'src/app/globals/services/permissions.service';
import { SampleService } from 'src/app/globals/services/sample.service';
import { SessionService } from 'src/app/globals/services/session.service';
import { UserService } from 'src/app/globals/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class IclLabsService {
  constructor(
    private sampleService: SampleService,
    private budgetsService: BudgetService,
    private aspectService: AspectService,
    private analyzeService: AnalyzeService,
    private categoryService: CategoryService,
    private factoryService: FactoryService,
    private permissionsService: PermissionsService,
    private userService: UserService,
    private sessionService: SessionService,
  ) {}

  async getUsers(): Promise<User[]> {
    const labId = this.sessionService.getLabId();
    return await this.userService.getUsers({labId});
  }



  async deleteSample(sample: Sample): Promise<any> {
    return await this.sampleService.deleteSample(sample.id);
  }

  async getSamples(): Promise<Sample[]> {
    return await this.sampleService.getSamples();
  }

  async postSample(sample: Sample): Promise<Sample> {
    const result = await this.sampleService.postSample(sample);
    return result;
  }

  async getSample(id: number): Promise<Sample> {
    const result = await this.sampleService.getSample(id);
    return result;
  }

  async putSample(
    sample: Partial<Sample>,
    id: number
  ): Promise<{ success: boolean }> {
    const result = await this.sampleService.putSample(sample, id);
    return result;
  }

  async getBudgets(params?: any): Promise<BudgetItem[]> {
    const result = await this.budgetsService.getBudgets(params);
    return result;
  }

  async getAspects(params?: any): Promise<Aspect[]> {
    const result = await this.aspectService.getAspects();
    return result;
  }

  async getAnalyzes(): Promise<Analyze[]> {
    const result = await this.analyzeService.getAnalyzes();
    return result;
  }

  async getCategories(): Promise<Category[]> {
    const result = await this.categoryService.getCategories();
    return result;
  }

  async getFactories(): Promise<Factory[]> {
    const result = await this.factoryService.getAllFactories();
    return result;
  }

  canEdit(user: User, sample: Sample, input?: string): boolean {
    return this.permissionsService.canEdit(user, sample, input);
  }

  isAdmin(user: User): boolean {
    return this.permissionsService.isAdmin(user);
  }
  isLabTec(user: User): boolean {
    return this.permissionsService.isLabTec(user);
  }
  isCustomer(user: User): boolean {
    return this.permissionsService.isCustomer(user);
  }

  isLabManager(user: User): boolean {
    return this.permissionsService.isLabManager(user);
  }

  canViewSample(user: User, sample: Sample): boolean {
    return this.permissionsService.canViewSampleEdit(user, sample);
  }

  canViewSampleEditAsLab(user: User, sample: Sample): boolean {
    return this.permissionsService.canViewSampleEditAsLab(user, sample);
  }

  async handleFile(e: any, sample: Sample): Promise<string> {
    if (e.target && e.target.files.length) {
      const fileName = await this.sampleService.postExcel(
        sample.id,
        e.target.files
        // e.target.files[0]
      );
      if (fileName) {
        console.log('uploaded');
        return fileName;
      }
    }
    return null;
  }

  async handleDownload(e: any, sample: Sample) {
    if (sample && sample.excel) {
      this.sampleService.getExcel(sample.excel);
    }
  }

  async searchSample(attrs: string): Promise<Sample[]> {
    if (attrs) return await this.sampleService.search(attrs);
  }
}
