import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule, CanLoad, Router } from '@angular/router';
import { PermissionsService } from './globals/services/permissions.service';
import { SessionService } from './globals/services/session.service';


@Injectable({ providedIn: 'root' })
export class CanLoadIclLabs implements CanLoad {
    constructor(
        private session: SessionService,
        private router: Router
    ) { }
    async canLoad(): Promise<boolean> {
        const user = await this.session.update();
        if (user)
            return true;
        this.router.navigate(['auth/login']);
        return false;
    }
}

@Injectable({ providedIn: 'root' })
export class CanLoadLab implements CanLoad {
    constructor(
        private session: SessionService,
        private router: Router,
        private permissionsService: PermissionsService,
    ) { }

    async canLoad(): Promise<boolean> {
        const user = await this.session.update();
        if (user && this.permissionsService.canViewLabArea(user))
            return true
        this.router.navigate(['']);
        return false;
    }
};

@Injectable({ providedIn: 'root' })
export class CanLoadAdmin implements CanLoad {
    constructor(
        private session: SessionService,
        private router: Router,
        private permissionsService: PermissionsService,
    ) { }

    async canLoad(): Promise<boolean> {
        const user = await this.session.update();
        if ((user && this.permissionsService.canViewAdminArea(user))||(this.permissionsService.canViewWorkerLabArea(user)))
            return true;
        this.router.navigate(['']);
        return false;
    }
};

const routes: Routes = [
    { path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
    { path: 'lab', canLoad: [CanLoadLab], loadChildren: () => import('./modules/lab/lab.module').then(m => m.LabModule) },
    { path: 'admin', canLoad: [CanLoadAdmin], loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule) },
    { path: '', canLoad: [CanLoadIclLabs], loadChildren: () => import('./modules/icl-labs/icl-labs.module').then(m => m.IclLabsModule) },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        CanLoadLab,
        CanLoadAdmin,
        CanLoadIclLabs
    ]
})
export class AppRoutingModule { }
