<ng-container *ngFor="let matter of matters; let i = index">
    <div class="d-flex justify-content-between align-items-end mb-4">
        <div class="col d-flex align-items-center size-small-normal">
            <mat-checkbox [disabled]="disabled" (change)="onMatterChange(i) " [(ngModel)]="matter.activate"
                color="primary">{{ getNameOfType(matter) }}</mat-checkbox>
        </div>
        <div class="col d-flex align-items-center justify-content-between">
            <span style="margin-left: 20px;" class="size-small-normal">מס' דוגמאות</span>
            <input *ngIf="currentLabId==3" (keyup)="onMatterChange() ;setFormLength(matter.amount)"  [disabled]="(disabled) || (!matter.activate) " class="cus-number"
                type="tel" [(ngModel)]="matter.amount" min="0">
                <input *ngIf="currentLabId==1" (keyup)="onMatterChange() ;setFormLength(matter.amount)"  [disabled]="(disabled) || (!matter.activate) " class="cus-number"
                type="tel" [(ngModel)]="matter.amount" min="0">
                <input *ngIf="currentLabId==2"  (keyup)="onMatterChange()" [disabled]="(disabled) || (!matter.activate) " class="cus-number"
                type="tel" [(ngModel)]="matter.amount" min="0">
              
        </div>
        <div *ngIf="currentLabId==3">
            <div  *ngFor="let x of this.gDynamicArr; let y = index ">
            <form *ngIf="this.flag"  [formGroup]="gDynamicForm" >   
                <input  (change)="onChangeTextInG(y)"  formControlName='newG{{y}}' type="text"
                  *ngIf="this.matter.activate&& this.matter.amount>0&&this.matter.type===0" style="margin-left: 10px;" class="cus-descrption"
                  min="0" placeholder="הזן פרטי דוגמה {{y+1}}">
            </form>
        </div>
        <div  *ngFor="let x of this.lDynamicArr; let y = index ">
            <form *ngIf="this.flag"  [formGroup]="lDynamicForm" >   
                <input  (change)="onChangeTextInL(y)"  formControlName='newL{{y}}' type="text"
                  *ngIf="this.matter.activate&& this.matter.amount>0&&this.matter.type===1" style="margin-left: 10px;" class="cus-descrption"
                  min="0" placeholder="הזן פרטי דוגמה {{y+1}}">
            </form>
        </div>
        <div  *ngFor="let x of this.sDynamicArr; let y = index ">
            <form *ngIf="this.flag"  [formGroup]="sDynamicForm" >   
                <input  (change)="onChangeTextInS(y)"  formControlName='newS{{y}}' type="text"
                  *ngIf="this.matter.activate&& this.matter.amount>0&&this.matter.type===2" style="margin-left: 10px;" class="cus-descrption"
                  min="0" placeholder="הזן פרטי דוגמה {{y+1}}">
            </form>
        </div>
    </div>
    <div *ngIf="currentLabId==1">
        <div  *ngFor="let x of this.gDynamicArr; let y = index ">
        <form *ngIf="this.flag"  [formGroup]="gDynamicForm" >   
            <input  (change)="onChangeTextInG(y)"  formControlName='newG{{y}}' type="text"
              *ngIf="this.matter.activate&& this.matter.amount>0&&this.matter.type===0" style="margin-left: 10px;" class="cus-descrption"
              min="0" placeholder="הזן פרטי דוגמה {{y+1}}">
        </form>
    </div>
    <div  *ngFor="let x of this.lDynamicArr; let y = index ">
        <form *ngIf="this.flag"  [formGroup]="lDynamicForm" >   
            <input  (change)="onChangeTextInL(y)"  formControlName='newL{{y}}' type="text"
              *ngIf="this.matter.activate&& this.matter.amount>0&&this.matter.type===1" style="margin-left: 10px;" class="cus-descrption"
              min="0" placeholder="הזן פרטי דוגמה {{y+1}}">
        </form>
    </div>
    <div  *ngFor="let x of this.sDynamicArr; let y = index ">
        <form *ngIf="this.flag"  [formGroup]="sDynamicForm" >   
            <input  (change)="onChangeTextInS(y)"  formControlName='newS{{y}}' type="text"
              *ngIf="this.matter.activate&& this.matter.amount>0&&this.matter.type===2" style="margin-left: 10px;" class="cus-descrption"
              min="0" placeholder="הזן פרטי דוגמה {{y+1}}">
        </form>
    </div>
</div>
    
    </div>
</ng-container>