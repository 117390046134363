import { Entity } from './Entity';

export class BudgetItem extends Entity {

    id: number;
    number: number;
    name: string;
    companyId: any;
    factoryId: number;
    description: string;

    constructor(args: Partial<BudgetItem>) {
        super(args);
        if (!args.description)
            this.description = "לא קיים תיאור";
    }
}