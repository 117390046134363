import { Injectable } from "@angular/core";
import { AdalService } from "adal-angular4";
import { User } from "../base/User";
import { IclLabsAPIService } from "./icl-labs.api.service";

@Injectable({
    providedIn: "root"
})

export class UserService {


    constructor(private iclLabsApiService: IclLabsAPIService, private adalService: AdalService) { }

    async getCurrentUser(labId = null): Promise<User> {
        try {
            let user: User;
            if (this.adalService) {
                if (this.adalService.userInfo.authenticated) {
                    let adalUser = await this.adalService.getUser().toPromise();
                    if (adalUser && adalUser.userName) {
                        let result = await this.iclLabsApiService.post('/user/login', { body: { email: adalUser.userName, labId: labId } });
                         if (result && result.user) {
                            result.user["labsRolePermission"] = result.labsRole;
                            //up this data to header and build select dynamic
                            user = new User(result.user); 
                            let userPermissionForLab = user.labsRolePermission.filter( lab => 1 == lab.permission.defaultLab );
                            user.Permission = userPermissionForLab.length>0  && userPermissionForLab[0]['permission']? userPermissionForLab[0]['permission'] :undefined
                        }
                    }
                }
            }
            return user
        }
        catch (err) {
            console.error(err);
        }
    }

    async getUsers(params?: any): Promise<User[]> {
        try {
            let users: User[] = [];
            const result = await this.iclLabsApiService.get('/user', { params });
            if (result && result.users) {
                result.users.map(user => users.push(new User(user)));
            }
            return users;
        } catch (err) {
            console.error(err);
        }
    }

    async createUser(user: Partial<User>): Promise<User> {
        try {
            const result = await this.iclLabsApiService.post('/user', { body: user });
            if (result && result.user) {
                return new User(result.user)
            } else {
                throw new Error('something went wrong')
            }
        }
        catch (err) {
            console.error(err);
        }
    }

    async editUser(id: number, user: Partial<User>): Promise<boolean> {
        try {
            const result = await this.iclLabsApiService.put('/user/' + id, { body: user });
            if (result && result.success) {
                return result.success;
            }
            return false;
        } catch (err) {
            console.error(err);
        }
    }
    async setDefault(id: number,user: Partial<User>): Promise<boolean> {
        try {
            const result = await this.iclLabsApiService.put('/user/' + id, { body: user});
            if (result && result.success) {
                return result.success;
            }
            return false;
        } catch (err) {
            console.error(err);
        }
    }


    async deleteUser(id: number, params?: any): Promise<boolean> {
        try {
            const result = await this.iclLabsApiService.delete('/user/' + id, { params });
            if (result && result.success) {
                return result.success;
            }
            return false;
        } catch (err) {
            console.error(err);
        }
    }
}