import { Injectable } from '@angular/core';
import { Factory } from '../base/User';
import { IclLabsAPIService } from './icl-labs.api.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class FactoryService {
  constructor(private iclLabsApiService: IclLabsAPIService, private sessionService : SessionService) { }

  async getAllFactories(): Promise<Factory[]> {
    try {
      let factories: Factory[] = [];
      const params = { labId: this.sessionService.getLabId() };
      
      const result = await this.iclLabsApiService.get('/factory', { params });
      if (result && result.factories) {
        result.factories.map((factory) => factories.push(new Factory(factory)));
      }
      return factories;
    } catch (err) {
      console.error(err);
    }
  }

  async createFactory(factory: Partial<Factory>): Promise<Factory> {
    try {
      const labId = this.sessionService.getLabId();
      factory["labId"] = labId;
      const result = await this.iclLabsApiService.post('/factory', {
        body: factory,
      });
      if (result && result.factory) {
        return new Factory(result.factory);
      } else {
        throw new Error('something went wrong');
      }
    } catch (err) {
      throw new Error(err);
    }
  }

  async editFactory(id: number, factory: Partial<Factory>): Promise<boolean> {
    try {
      const result = await this.iclLabsApiService.put('/factory/' + id, {
        body: factory,
      });
      if (result && result.success) {
        return result.success;
      }
      return false;
    } catch (err) {
      console.error(err);
    }
  }

  async deleteFactory(id: number, params?: any): Promise<boolean> {
    try {
      const result = await this.iclLabsApiService.delete('/factory/' + id, {
        params,
      });
      if (result && result.success) {
        return result.success;
      }
      return false;
    } catch (err) {
      console.error(err);
    }
  }
}
