import { Entity } from './Entity';

export class Aspect extends Entity {

    id: number;
    name: string;
    description: string;
    guidance: string;
    factoryId: number;

    constructor(args: Partial<Aspect>) {
        super(args);
    }
}