import { Entity } from './Entity';

export class Category extends Entity {

    id: number;
    name: string;
    order: number;

    constructor(args: Partial<Category>) {
        super(args);
    }
}