export const statuses: Status[] = [
    {
        id: 0,
        color: 'rgba(0, 0, 0, 0.2)',
        title: 'נוצר'
    },
    {
        id: 1,
        color: 'rgba(238, 218, 42, 1)',
        title: 'נמסר למעבדה'
    },
    {
        id: 2,
        color: 'rgba(183, 186, 182, 1)',
        title: 'התקבל'
    },
    {
        id: 3,
        color: 'rgba(199, 42, 238, 1)',
        title: 'בעבודה'
    },
    {
        id: 4,
        color: 'rgba(42, 226, 238, 1)',
        title: 'בדיקה מחודשת'
    },
    {
        id: 5,
        color: 'rgba(238, 42, 42, 1)',
        title: 'נדחה'
    },
    {
        id: 6,
        color: 'rgba(127, 17, 137, 1)',
        title: 'ממתין לאישור'
    },
    {
        id: 7,
        color: 'rgba(104, 238, 42, 1)',
        title: 'הסתיים ואושר'
    },
    {
        id: 8,
        color: 'rgba(104, 238, 42, 1)',
        title: 'מבוטל'
    }
]

export interface Status {
    id: number;
    color: string;
    title: string;
    reasonReject?: string;
}
export enum STATUS_CONST {
    STATUS_CREATED = 0,
    STATUS_HANDED_TO_LAB = 1,
    STATUS_IN_LAB = 2,
    STATUS_REJECT = 5,
    STATUS_WORK = 3,
    STATUS_DONE = 7,
    STATUS_CANCELED = 8,
}