import * as moment from 'moment';

export class Entity {
    constructor(args?: any) {
        if (typeof args != "undefined") {
            Object.assign(this, args);
        }
    }

    getAsTime(date: Date): string {
        return moment(date).format("HH:mm");
    }

    getAsDate(date: Date): string {
        if (date)
            return moment(date).format("DD/MM/YYYY");
        else {
            return 'לא קיים תאריך';
        }
    }

    getAsDateTime(date: Date): string {
        if (date)
            return moment(date).format("DD/MM/YYYY HH:mm");
        else {
            return 'לא קיים זמן';
        }
    }
}
