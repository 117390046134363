import { Injectable } from '@angular/core';
import { Factory, User } from '../base/User';
import { IclLabsAPIService } from './icl-labs.api.service';
import { SessionService } from './session.service';
import { SavedSearches } from '../base/SavedSearches';
import { UserService } from './user.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class savedSearchesService {
  user: User;
  private savesearch= new Subject<any>();
  public savesearch$= this.savesearch.asObservable();
  private savedFilters= new Subject<any>();
  public savedFilters$= this.savedFilters.asObservable();
  constructor(private iclLabsApiService: IclLabsAPIService, private sessionService : SessionService, private userService: UserService,) { }

  async getsavedSearche(): Promise<SavedSearches[]> {
    this.sessionService.user.subscribe(async (user: User) => {
      this.user = user;});
   
    try {
      let savedSearches: SavedSearches[] = [];
      const params = { labId: this.sessionService.getLabId(),userId:this.user.id};
      
      const result = await this.iclLabsApiService.get('/savedSearches', { params });
    
      if (result && result.savedSearches) {
        result.savedSearches.map((savedSearches) => savedSearches.push(new SavedSearches(savedSearches)));
      }
      this.savesearch.next(result['Savedsearches']);
      this.savedFilters.next();
      return result['Savedsearches'];
    } catch (err) {
      console.error(err);
    }
  }

  async createsavedSearche(savedSearches: Partial<SavedSearches>): Promise<SavedSearches> {
    try {
      const result = await this.iclLabsApiService.post('/savedSearches', {
        body: savedSearches,
      });
      if (result && result.savedSearches) {
        return new SavedSearches(result.savedSearches);
      } else {
        throw new Error('something went wrong');
      }
    } catch (err) {
      throw new Error(err);
    }
  }
  async updatesavedSearche(savedSearches: Partial<SavedSearches>): Promise<SavedSearches> {
    try {
      const result = await this.iclLabsApiService.post('/savedSearches/save', {
        body: savedSearches,
      });
      if (result && result.savedSearches) {
        return new SavedSearches(result.savedSearches);
      } else {
        throw new Error('something went wrong');
      }
    } catch (err) {
      throw new Error(err);
    }
  }


  async setDefaultSearch(id: number, savedSearches: []): Promise<boolean> {
    try {
      const result = await this.iclLabsApiService.put('/savedSearches/' + id, {
        body: savedSearches,
      });
      if (result && result.success) {
        return result.success;
      }
      return false;
    } catch (err) {
      console.error(err);
    }
  }

  async deletesavedSearche(id: number, params?: any): Promise<boolean> {
    try {
      const params = { labId: this.sessionService.getLabId()};
      const result = await this.iclLabsApiService.delete('/savedSearches/' + id, {
        params,
      });
      if (result && result.success) {
        return result.success;
      }
      return false;
    } catch (err) {
      alert("לא נבחר חיפוש קיים");
      console.error(err);
    }
  }
}
